import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';

import {CSSTransition} from "react-transition-group";

import logoRock from '../assets/img/logos/logo_fels_dunkel.svg';
import logoHydro from '../assets/img/logos/logo_hydro_dunkel.svg';
import logoGeo from '../assets/img/logos/logo_geo_dunkel.svg';

import logoRockSmall from '../assets/img/pins/map_pin_fels.png';
import logoHydroSmall from '../assets/img/pins/map_pin_wasser.png';
import logoGeoSmall from '../assets/img/pins/map_pin_boden.png';


class TopBar extends Component {

    constructor(props) {
        super(props);

        this.state = {
            open: false
        };

        this.scroll();
        this.clickAndScroll = this.clickAndScroll.bind(this);
        this.openMenu = this.openMenu.bind(this);
    }

    scroll(to) {
        window.addEventListener('scroll', () => {
            let topDistance = window.scrollY;
            let logo = document.querySelector(".logo");
            let start = document.querySelector("#start");

            if(!!start) {
                let startProps = start.getBoundingClientRect();

                if (!!logo) {
                    logo.style.opacity = topDistance / (startProps.height/3);
                }
            }
        });
    }

    clickAndScroll(id) {

        let elm = document.querySelector(id);

        let header = document.querySelector('header');
        let headerData = header.getBoundingClientRect();


        if (!!elm) {
            let position = elm.offsetTop - headerData.height;

            window.scroll({
                top: position,
                behavior: 'smooth'
            });
        }

        this.setState({
            open: false
        });

    }

    openMenu() {
        let state = !this.state.open;

        this.setState({open: state});


        // let html = document.querySelector('html');
        // if (state) {
        //     html.style.overflow = 'hidden';
        // } else {
        //     html.removeAttribute('style');
        // }
    }

    render() {

        let path = this.props.location.pathname;

        let logo;
        let logoSmall;

        if (this.props.location.pathname === '/felsprojekt') {
            logo = logoRock;
            logoSmall = logoRockSmall;
        } else if (this.props.location.pathname === '/hydroprojekt') {
            logo = logoHydro;
            logoSmall = logoHydroSmall;
        } else {
            logo = logoGeo;
            logoSmall = logoGeoSmall;
        }

        return (
            <>
                <header className="header">

                    <nav>
                        <div className={'logoBox'}>
                            <div className={'logo'}>
                                <img src={logo} id={'logo'} className={'_logo'} alt={'witt & partner Logo'} title={'Startseite'} onClick={() => this.props.goto('#start')} width={145} height={35} />
                                <img src={logoSmall} id={'logoSmall'} className={'_logoSmall'} alt={'witt & partner Logo'} title={'Startseite'} onClick={() => this.props.goto('#start')} width={24} height={34} />
                            </div>
                        </div>

                        <div className={'centerBox'}>
                            <Link className={'geoprojekt' + ((path === '/' || path === '/geoprojekt') ? ' currentGround current' : '')} to={'/'}>
                                <span className={'icon icon-boden'} />
                                <span className={'linkText'}>Boden</span>
                            </Link>

                            <Link className={'felsprojekt' + ((path === '/felsprojekt') ? ' currentRock current' : '')} to={'/felsprojekt'}>
                                <span className={'icon icon-fels'} />
                                <span className={'linkText'}>Fels</span>
                            </Link>
                            <Link className={'hydroprojekt' + ((path === '/hydroprojekt') ? ' currentWater current' : '')} to={'/hydroprojekt'}>
                                <span className={'icon icon-wasser'} />
                                <span className={'linkText'}>Wasser</span>
                            </Link>
                        </div>

                        <div className={'flex'} />

                    </nav>

                    <div className={'menu'} onClick={() => this.openMenu()}>
                        <div className={'menuBox ' + (this.state.open ? 'opened' : '')}>
                            <span className="line_1" />
                            <span className="line_2" />
                            <span className="line_3" />
                        </div>
                    </div>

                </header>

                <CSSTransition in={this.state.open === true} timeout={200} classNames={'overlay'}>
                    <div id={'overlay'} className={(this.state.open ? 'opened' : '')} onClick={() => this.setState({open: false})} />
                </CSSTransition>

                <div id={'mainMenu'} className={(this.state.open ? 'opened' : '')}>

                    <div className={'logoBox'}>
                        <img src={logo} className={'logo'} alt={'witt & partner Logo'} width={280} height={80} />
                    </div>

                    <div className={'linkliste'}>
                        {
                            this.props.data &&
                            this.props.data.map((elm, key) => {
                                let openTab = (!!elm['Tab']) ? () => this.props.openTab(elm['Link'] + 'Tab', elm['Tab']) : null;
                                let imprint = (elm['Link'] === 'impressum') ? () => this.props.showImprint() : null;

                                return <Link to={'#' + elm['Link']} key={'link_' + elm['Link'] + '_' + key} onClick={(e) => {
                                    if (!!imprint) {
                                        e.preventDefault();

                                        imprint();

                                        this.setState({open: false});
                                    } else {
                                        this.clickAndScroll('#' + elm['Link']);

                                        if (openTab) {
                                            openTab();
                                        }
                                    }
                                }}>{elm['Name']}</Link>;
                            })
                        }
                    </div>

                </div>
            </>
        );
    }
}

export default withRouter(TopBar);
