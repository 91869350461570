import React, {Component} from 'react';
import {pdf} from "@react-pdf/renderer";
import {PdfDocument} from "./pdf";
import {saveAs} from 'file-saver';

const generatePdfDocument = async (fileName, documentData, done, section, filesection, businessArea, activityArea, service, sections, search) => {
    pdf(<PdfDocument data={documentData} section={filesection} businessArea={businessArea} activityArea={activityArea} service={service} sections={sections} search={search} />).toBlob().then((blob) => {
        saveAs(blob, fileName);
        done(section);
    });
};


class PdfDownload extends Component {

    constructor(props) {
        super(props);

        this.state = {
            a: false,
            b: false
        };

        this.done = this.done.bind(this);

        let full = this.props.allEntries[this.props.section];

        this.fullLen = 0;
        this.full = {};

        if (this.props.section === 'projects') {
            Object.keys(full).forEach(c => {
                return full[c].forEach((e, i) => {
                    if (!!e['Projektinfo']) {
                        if (!this.full[c]) {
                            this.full[c] = [];
                        }

                        this.fullLen+=1;
                        this.full[c].push(e);
                    }
                });
            });
        } else {
            Object.keys(full).forEach(c => {
                full[c].forEach((e, i) => {
                    if (!this.full[c]) {
                        this.full[c] = [];
                    }

                    this.fullLen+=1;
                    this.full[c].push(e);
                });
            });
        }
    }

    done(dl) {
        this.setState({
            [dl]: false
        }, () => {
            this.props.hideDownloads();
        })
    }

    render() {

        if (this.props.section === 'references') {
            this.what = 'Referenzliste';
        } else if (this.props.section === 'projects') {
            this.what = 'Referenzprojekte';
        } else {
            this.what = 'Veröffentlichungen';
        }

        let len = 0;
        if (this.props.businessArea === '' && this.props.section !== 'publications') {
            len = this.props.filtered[this.props.section].length;
        } else {
            if (!!this.props.filtered[this.props.section]) {
                len = this.props.filtered[this.props.section].length;
            } else {
                len = this.props.filtered[this.props.section].length;
            }
        }

        return (
            <div className={'downloadList'}>
                {(len > 0) &&
                    (this.state.a === true
                        ?
                            <div className={'link'} style={{lineHeight:'1.4rem', display:'flex'}}>
                                Generiere PDF-Dokument

                                <span className={'loading'}>
                                    <div className="spinner">
                                        <div className="bounce1" />
                                        <div className="bounce2" />
                                        <div className="bounce3" />
                                    </div>
                                </span>
                            </div>
                        :
                            <div className={'link'} onClick={() => {
                                this.setState({
                                    a: true
                                });
                            }}>
                                <span className={'text'}>
                                    Download {this.what} Auswahl {len}
                                </span>
                                <span className={'icon-download download'} />
                            </div>
                    )
                }


                {(this.full.length > 0 && this.state.b === true)
                ?
                    <div className={'link'} style={{lineHeight:'1.4rem', display:'flex'}}>
                        Generiere PDF-Dokument

                        <span className={'loading'}>
                                <div className="spinner">
                                    <div className="bounce1" />
                                    <div className="bounce2" />
                                    <div className="bounce3" />
                                </div>
                            </span>
                    </div>
                :
                    <div className={'link'} onClick={() => {
                        this.setState({
                            b: true
                        });
                    }}>
                            <span className={'text'}>
                                Download {this.what} Gesamt {this.fullLen}
                            </span>
                        <span className={'icon-download download'} />
                    </div>
                }
            </div>
        );
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.a !== this.state.a && this.state.a === true) {
            generatePdfDocument(this.what + '_Auswahl.pdf', {filtered: this.props.filtered[this.props.section], full: this.full}, this.done, 'a', this.props.section, this.props.businessArea, this.props.activityArea, this.props.service, this.props.sections, this.props.search);
        }

        if (prevState.b !== this.state.b && this.state.b === true) {
            generatePdfDocument(this.what + '_Komplett.pdf', {full: this.full}, this.done, 'b', this.props.section, this.props.businessArea, this.props.activityArea, this.props.service, this.props.sections, this.props.search)
        }
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if (nextProps.section !== this.props.section) {
            let full = nextProps.allEntries[nextProps.section];
            this.fullLen = 0;

            if (nextProps.section === 'projects') {
                Object.keys(full).forEach(c => {
                    return full[c].forEach((e, i) => {
                        if (!!e['Projektinfo']) {
                            if (!this.full[c]) {
                                this.full[c] = [];
                            }

                            this.fullLen+=1;
                            this.full[c].push(e);
                        }
                    });
                });
            } else {
                Object.keys(full).forEach(c => {
                    full[c].forEach((e, i) => {
                        if (!this.full[c]) {
                            this.full[c] = [];
                        }

                        this.fullLen+=1;
                        this.full[c].push(e);
                    });
                });
            }
        }

        return nextState !== this.state || nextProps.section !== this.props.section;
    }
}

export default PdfDownload;
