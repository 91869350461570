import React, {Suspense} from 'react';
import {BrowserRouter as Router} from "react-router-dom";

import AppRouter from './AppRouter.class';
import Loading from './loading';

import '@fontsource/merriweather-sans';
import '@fontsource/roboto';
import "@fontsource/roboto-serif";


function App() {

    let className = '';
    let path = window.location.pathname.replace('/', '');

    if (path === '' || path === 'geoprojekt') {
        className = 'geoprojekt';
    } else {
        className = path;
    }

    return (
        <Suspense fallback={<Loading css={className} />}>
            <Router>
                <AppRouter />
            </Router>
        </Suspense>
    );
}

export default App;
