import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';


class Services extends Component {
    render() {
        return (
            <div id={this.props.id} className={'services'}>
                <div className={'items'}>
                    {!!this.props.data &&
                        this.props.data.map((rubric, key) => {
                            return (
                                <div className={'item'} key={'section_item_' + key} onClick={() => this.props.goto(rubric['Tätigkeitsfeld'])}>
                                    <h3 className={'text'}>
                                        {rubric['Überschrift']}
                                    </h3>

                                    <div className={'box'}>
                                        <div className={'icon ' + rubric['Icon']}/>

                                        <div className={'hoverLayer'}>
                                            {rubric['Text']}
                                        </div>
                                    </div>

                                </div>
                            );

                        })
                    }
                </div>
            </div>
        );
    }
}

export default withRouter(Services);
