import React, {Component} from 'react';

class Loading extends Component {
    render() {
        return (
            <div className={'load ' + (!!this.props.css ? this.props.css : '')}>
                <div className="spinner">
                    <div className="bounce1" />
                    <div className="bounce2" />
                    <div className="bounce3" />
                </div>
            </div>
        );
    }
}

export default Loading;
