import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';

import '../assets/scss/about.scss';
import LazyImage from "./lazyImage.class";

import marked from './markdown';
import AboutImageGallery from "./aboutImageGallery.class";


class About extends Component {

    constructor(props) {
        super(props);

        this.state = {
            current: props.data.filter(h => h['Typ'] !== 'Ueber_Ueberschift')[0]['Typ'],
            currentSlide: 0,
            next: 1,
            prev: !!props.data ? props.data.length-1: 0
        };

        this.goTo = this.goTo.bind(this);
    }

    reset() {
        this.setState({
            current: this.props.data.filter(h => h['Typ'] !== 'Ueber_Ueberschift')[0]['Typ'],
            currentSlide: 0,
            next: 1,
            prev: !!this.props.data ? this.props.data.length-1: 0
        })
    }

    show(key) {
        this.setState({
            current: key
        });
    }

    group(elms) {
        let groups = {};

        if (!!elms && elms.length) {
            elms.forEach(e => {
                if (!groups[e['Typ']]) {
                    groups[e['Typ']] = [];
                }

                groups[e['Typ']].push(e);
            })
        }

        return groups;
    }

    goToIndex(i, slides) {
        let len = Object.keys(slides).length;
        let prev = this.state.currentSlide < len ? this.state.currentSlide : null;
        let next = this.state.currentSlide > len ? this.state.currentSlide : null;

        this.setState({
            prev: prev,
            next: next
        });

        setTimeout(() => {
            this.setState({
                currentSlide: i,
            });
            setTimeout(() => {
                this.setState({
                    prev: null,
                    next: null
                });
            },400);
        },100);
    }

    goTo(to, slides) {
        let current_current_elm = document.querySelector('#' + this.props.id + ' .tab.persons .slide.currentSlide');
        let current_next_elm = document.querySelector('#' + this.props.id + ' .tab.persons .slide.nextSlide');
        let current_prev_elm = document.querySelector('#' + this.props.id + ' .tab.persons .slide.prevSlide');

        let allSlides = document.querySelectorAll('#' + this.props.id + ' .tab.persons .slide');
        let next_current = '';

        current_current_elm.classList.remove('currentSlide');


        if (to === 'next') {
            if (!!current_current_elm.nextSibling) {
                next_current = current_current_elm.nextSibling;
            } else {
                next_current = allSlides[0];
            }

            next_current.classList.add('currentSlide');

            let next_next = '';
            if (!!next_current.nextSibling) {
                next_next = next_current.nextSibling;
            } else {
                next_next = allSlides[0];
            }
            next_next.style.removeProperty('transition');
            next_next.classList.add('nextSlide');


            let next_previous = '';
            if (!!next_current.previousSibling) {
                next_previous = next_current.previousSibling;
            } else {
                next_previous = allSlides[allSlides.length-1];
            }
            next_previous.classList.add('prevSlide');

        } else {
            if (!!current_current_elm.previousSibling) {
                next_current = current_current_elm.previousSibling;
            } else {
                next_current = allSlides[allSlides.length-1];
            }
            next_current.classList.add('currentSlide');


            // let next_next = '';
            // if (!!current_current_elm.nextSibling) {
            //     current_current_elm.nextSibling.style.removeProperty('left');
            // } else {
            //     allSlides[0].classList.add('nextSlide');
            // }

            let next_next = '';
            if (!!next_current.nextSibling) {
                next_next = next_current.nextSibling;
            } else {
                next_next = allSlides[0];
            }
            next_next.classList.add('nextSlide');

            let next_previous = '';
            if (!!next_current.previousSibling) {
                next_previous = next_current.previousSibling;
            } else {
                next_previous = allSlides[allSlides.length-1];
            }
            next_previous.style.transition = 'none';
            next_previous.classList.add('prevSlide');

            setTimeout(() => {
                next_previous.style.removeProperty('transition');
            }, 1)

        }

        current_current_elm.classList.remove('currentSlide');
        current_next_elm.classList.remove('nextSlide');
        current_prev_elm.classList.remove('prevSlide');

    }

    render() {

        if (!!this.props.data) {
            let headline = this.props.data.filter(h => h['Typ'] === 'Ueber_Ueberschift')[0];
            let filtered = this.props.data.filter(h => h['Typ'] !== 'Ueber_Ueberschift');
            let groups = this.group(filtered);

            return (
                <div id={this.props.id} className={'aboutContent'}>
                    <h2>
                        {headline['Text']}
                    </h2>

                    <ul className={'tabs'}>
                        {groups &&
                        Object.keys(groups).map((e, key) => {
                            let group = groups[e];
                            let headline;
                            if (group.length === 1) {
                                headline = group[0]['Tab'];
                            } else {
                                let _entry = group.filter(e => !!e['Tab']);
                                headline = _entry[0]['Tab'];
                            }

                            return <li className={(this.state.current===group[0]['Typ'] ? 'current': '')} onClick={() => this.show(group[0]['Typ'])} key={'tab_' + key}>{headline}</li>;
                        })
                        }
                    </ul>

                    <div className={'tabContent'}>

                        {groups &&
                        Object.keys(groups).map((e, key) => {
                            let group = groups[e];
                            let slides = [];

                            group.map((k, _i) => {
                                slides.push(k);

                                return null;
                            });


                            if (group.length === 1) {

                                let pos = (key % 2) ? 'left ' : 'right ';

                                let images = !!!!group[0]['Bild'] ? group[0]['Bild'].split(',') : [undefined];

                                return (
                                    <div className={'tab box ' + pos + (this.state.current===group[0]['Typ'] ? 'current': '')} key={'tab_' + key}>
                                        <div className="textBox">
                                            <div className="textBoxInner">
                                                <h3 className="headline">
                                                    {group[0]['Überschrift']}
                                                </h3>

                                                <p className="text" dangerouslySetInnerHTML={{__html: marked(group[0]['Text'])}} />
                                            </div>
                                        </div>

                                        <div className={'imageBox'}>
                                            <AboutImageGallery images={images} />
                                        </div>
                                    </div>
                                );
                            } else {
                                return (
                                    <div className={'tab persons ' + (this.state.current===group[0]['Typ'] ? 'current': '')} key={'tab_' + key}>

                                        <div className={'sliderBox'}>

                                            <div className="prev icon-prev" onClick={() => this.goTo('prev', slides)} />

                                            <div className={'slides'}>
                                                {slides.map((slide, key) => {

                                                    let cssClass = '';

                                                    if (key === 0) {
                                                        cssClass = 'currentSlide';
                                                    } else if (key === 1) {
                                                        cssClass = 'nextSlide';
                                                    } else if (key === slides.length-1) {
                                                        cssClass = 'prevSlide';
                                                    }

                                                    return (
                                                        <div className={'slide ' + cssClass} key={key}>
                                                            <div className={'slideContent'}>
                                                                <div className={'column'} key={'col_' + key}>
                                                                    <div className={'left'}>
                                                                        <div className={'outerImgBox'}>
                                                                            <div className={'imgBox'}>
                                                                                <LazyImage dkey={'person_img_' + key} alt={'person_img_' + key} src={'image.php?file=' + slide['Bild'] + '&height=400&width=400'} width={400} height={400} />
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className={'right'}>
                                                                        <div className={'top'} dangerouslySetInnerHTML={{__html: marked(slide['Überschrift'])}} />

                                                                        <div className={'contactData'} dangerouslySetInnerHTML={{__html: marked(slide['Text'])}}/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </div>

                                            <div className="next icon-next" onClick={() => this.goTo('next', slides)} />

                                            {/*<div className={'bubbleBox'}>*/}
                                            {/*    {Object.keys(slide).map((gr, k) => {*/}
                                            {/*        return <div key={'bubble_' + k} className={'bubble ' + ((k === this.state.currentSlide) ? 'current' : '')} onClick={() => this.goToIndex(k, slide)} />;*/}
                                            {/*    })}*/}
                                            {/*</div>*/}

                                        </div>
                                    </div>
                                );
                            }

                        })
                        }

                    </div>

                    <div className="horizontalLine" />

                </div>
            );
        } else {
            return <></>;
        }

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.tab !== this.props.tab) {
            this.setState({
                current: this.props.tab
            })
        }

        if (prevProps.resetAll !== this.props.resetAll && !!this.props.resetAll) {
            this.reset();
        }
    }
}

export default withRouter(About);
