import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';

import {GoogleMap, Marker, InfoWindow, LoadScript} from "@react-google-maps/api";
import LazyImage from "./lazyImage.class";

import cookie from "cookiejs";
import Loading from "../loading";

import geo from '../assets/img/pins/map_pin_boden.png';
import rock from '../assets/img/pins/map_pin_fels.png';
import water from '../assets/img/pins/map_pin_wasser.png';


class Map extends Component {
    constructor(props) {
        super(props);

        this.state = {
            bounds: null,
            markers: [],
            showingInfoWindow: false,
            selectedPlace: {},
            zoom: 12,
            center: null,
            load: cookie.get('wup_cookie') === '1',
            libs: ['places','geometry'],
        };

        this.onReady = this.onReady.bind(this);
        this.onMarkerClick = this.onMarkerClick.bind(this);
        this.onInfoWindowClose = this.onInfoWindowClose.bind(this);
    }


    onMarkerClick(marker) {
        this.setState({
            showingInfoWindow: true,
            selectedPlace: marker
        });
    }

    clickAndScroll(id) {

        let map = document.querySelector('.mapBox');
        let position = map.offsetTop;

        if (!!map) {
            window.scroll({
                top: position,
                behavior: 'smooth'
            });
        }

        this.setState({
            showingInfoWindow: true,
            selectedPlace: this.state.markers[id],
            center: this.state.markers[id],
            zoom: 16
        });
    }


    onInfoWindowClose() {
        if (this.state.showingInfoWindow) {
            this.setState({
                showingInfoWindow: false,
                selectedPlace: {}
            })
        }
    }

    makeBounds = (map) => {

        if (!map) return false;

        let points = [];
        let bounds = new window.google.maps.LatLngBounds();

        if (!!this.props['data']) {
            this.props['data'].forEach(data => {
                points.push({
                    lat: parseFloat(data['Latitude']),
                    lng: parseFloat(data['Longitude']),
                });
            });
        }


        if (!!points && points.length) {
            for (let i = 0; i < points.length; i++) {
                bounds.extend(points[i]);
            }
        }

        if (!!points && points.length > 1 && typeof map.fitBounds !== 'undefined') {
            map.fitBounds(bounds);
        }

    };

    onReady = (map) => {

        if (!map) return false;
        this.map = map;


        const google = window.google;
        let $this = this;

        map.addListener('zoom_changed', function() {
            $this.setState({
                zoom: map.getZoom()
            })
        });

        setTimeout(() => {
            google.maps.event.trigger(map, 'resize');
            this.makeBounds(map);
        }, 300);
    };


    getMapStyle = () => {
        return [
            {
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#f5f5f5"
                    }
                ]
            },
            {
                "elementType": "labels.icon",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#616161"
                    }
                ]
            },
            {
                "elementType": "labels.text.stroke",
                "stylers": [
                    {
                        "color": "#f5f5f5"
                    }
                ]
            },
            {
                "featureType": "administrative.land_parcel",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#bdbdbd"
                    }
                ]
            },
            {
                "featureType": "poi",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#eeeeee"
                    }
                ]
            },
            {
                "featureType": "poi",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#757575"
                    }
                ]
            },
            {
                "featureType": "poi.park",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#e5e5e5"
                    }
                ]
            },
            {
                "featureType": "poi.park",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#9e9e9e"
                    }
                ]
            },
            {
                "featureType": "road",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#ffffff"
                    }
                ]
            },
            {
                "featureType": "road.arterial",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#757575"
                    }
                ]
            },
            {
                "featureType": "road.highway",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#dadada"
                    }
                ]
            },
            {
                "featureType": "road.highway",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#616161"
                    }
                ]
            },
            {
                "featureType": "road.local",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#9e9e9e"
                    }
                ]
            },
            {
                "featureType": "transit.line",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#e5e5e5"
                    }
                ]
            },
            {
                "featureType": "transit.station",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#eeeeee"
                    }
                ]
            },
            {
                "featureType": "water",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#c9c9c9"
                    }
                ]
            },
            {
                "featureType": "water",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#9e9e9e"
                    }
                ]
            }
        ];
    };

    changeFavicon(icn) {
        let link = document.querySelector("link[rel*='icon']");
        link.href = icn;
    }

    showMap() {
        // cookie.set('wup_cookie', '1');
        // this.setState({
        //     load: true
        // });
        this.props.loadGoogleScripts();
    }

    render() {
        this.params = this.props.location.pathname.replace('/', '');

        let pin;
        let className = '';

        if (this.params === '' || this.params === 'geoprojekt') {
            pin = geo;
            className = 'geoprojekt';
        } else if (this.params === 'felsprojekt') {
            pin = rock;
            className = this.params;
        } else if (this.params === 'hydroprojekt') {
            pin = water;
            className = this.params;
        } else {
            pin = geo;
            className = this.params;
        }


        this.changeFavicon(pin);

        return (
            <>
                <div id={this.props.id} className={'mapBox'}>

                    <div className={'horizontalLine'} />

                    <h2>Unsere Standorte</h2>

                    <div className={'mapBoxInner'}>
                        {(this.state.load && cookie.get('wup_cookie') === '1') ?
                            <LoadScript id={'script-loader'} googleMapsApiKey={'AIzaSyA16TsCmSRZlT_VazIE4kF_izuvA6ZLMxY'} libraries={this.state.libs} language={navigator.language || navigator['userLanguage']} loadingElement={<Loading css={className} />}>
                                <GoogleMap id={'map'} ref={(ref) => { this.map = ref; }} onLoad={this.onReady} options={{
                                    fullscreenControl: true,
                                    scrollwheel: false,
                                    controlSize: 30,
                                    styles: this.getMapStyle(),
                                    minZoom:6
                                }} containerStyle={{
                                    width: '100%',
                                    height: '100%',
                                    overflow: 'hidden',
                                    position: 'relative'
                                }} {...(this.state.center) ? {
                                    center: this.state.center,
                                } : {}} zoom={this.state.zoom}>

                                    {
                                        !!window.google && !!window.google.maps && Object.keys(this.state.markers).map((i) => {
                                            return <Marker
                                                    key={'__' + i}
                                                    onClick={() => this.onMarkerClick(this.state.markers[i])}
                                                    name={this.state.markers[i]['name']}
                                                    icon={{
                                                        scaledSize: new window.google.maps.Size(30, 40),
                                                        url: pin,
                                                        origin: new window.google.maps.Point(0, 0),
                                                        anchor: new window.google.maps.Point(15, 40)
                                                    }}
                                                    position={{
                                                        lat: this.state.markers[i].lat,
                                                        lng: this.state.markers[i].lng
                                                    }
                                                    }>
                                                </Marker>
                                            }
                                        )
                                    }

                                    {
                                        this.state.showingInfoWindow &&

                                        <InfoWindow key={'_'} onCloseClick={this.onInfoWindowClose} position={this.state.selectedPlace}>
                                            <div className={'infoBox'}>
                                                {this.state.selectedPlace['data']['name']}

                                                <div className={'mapAdress'}>
                                                    <div className={'icn'}>
                                                        <img src={pin} alt={'pin'} width={30} height={40} />
                                                    </div>
                                                    <address>
                                                        {this.state.selectedPlace['data']['street']}<br />
                                                        {this.state.selectedPlace['data']['city']}
                                                    </address>
                                                </div>
                                            </div>
                                        </InfoWindow>
                                    }

                                </GoogleMap>
                            </LoadScript>:

                            <React.Fragment>
                                <div className={'loadMap'}>
                                    <h2>Karte anzeigen</h2>
                                    <h3>BITTE KLICKEN SIE, UM DIE INTERAKTIVE GOOGLE KARTE ANZUZEIGEN.<br />
                                        DABEI WERDEN GERÄTEDATEN MIT GOOGLE AUSGETAUSCHT.</h3>
                                    <button className={'button'} type={'button'} onClick={() => this.showMap()}>Google Maps anzeigen</button>
                                </div>
                            </React.Fragment>
                        }
                    </div>

                    <div className={'locations'}>
                        {
                            this.props['data'].map((data, key) => {
                                return (
                                    <div key={key} className={'location'}>

                                        <h3>{data['Überschrift']}</h3>

                                        <div className={'imageBox'}>
                                            <LazyImage dkey={'location_img_' + key} className={'img'} alt={'location_img_' + key} src={'image.php?file=' + data['Bild'] + '&height=200&width=360'} width={360} height={200} />
                                            <div className={'hoverBox'}>
                                                <button onClick={() => this.clickAndScroll(key)}>Auf der Karte anzeigen</button>
                                            </div>
                                        </div>

                                        <address>
                                            <h4>{data['Name']}</h4>
                                            {/*{data['Überschrift'] !== 'Büro Bad Tölz' && <><br /><br /></>}*/}

                                            <div className={'street'}>
                                                {data['Straße']}
                                            </div>

                                            <div>{data['Stadt']}</div>
                                            <br />
                                            {/*{data['Überschrift'] !== 'Büro Bad Tölz' && <br />}*/}


                                            <div className={'contactData'}>
                                                <div>
                                                    <span>
                                                        Tel: {data['Telefon']}
                                                    </span>
                                                </div>

                                                <div>
                                                    <span>
                                                        Fax: {data['Telefax']}
                                                    </span>
                                                </div>

                                                <div>
                                                    <a href={'mailto:' + data['Mail']}>
                                                        Mail: {data['Mail']}
                                                    </a>
                                                </div>
                                            </div>
                                        </address>

                                    </div>
                                );
                            })
                        }
                    </div>

                </div>
            </>
        );
    }

    componentDidMount() {
        let points = {};

        if (!!this.props['data']) {
            this.props['data'].forEach((data, key) => {
                points[key] = {
                    lat: parseFloat(data['Latitude']),
                    lng: parseFloat(data['Longitude']),
                    data: {
                        name: data['Name'],
                        street: data['Straße'],
                        city: data['Stadt'],
                    },
                };
            });
        }

        if ( Object.keys(points).length > 1 ) {
            this.setState({
                markers: points,
            });
        } else {
            this.setState({
                markers: points,
                center: points[0],
                zoom: 16
            });
        }

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.data !== this.props.data) {
            let points = {};

            if (!!this.props['data']) {
                this.props['data'].forEach((data, key) => {
                    points[key] = {
                        lat: parseFloat(data['Latitude']),
                        lng: parseFloat(data['Longitude']),
                        data: {
                            name: data['Name'],
                            street: data['Straße'],
                            city: data['Stadt'],
                        },
                    };
                });
            }

            if ( Object.keys(points).length > 1 ) {
                this.setState({
                    markers: points,
                });

                if (!!this.map) {
                    this.makeBounds(this.map.state.map);
                }
            } else {
                this.setState({
                    markers: points,
                    center: points[0],
                    zoom: 16
                });
            }
        }

        if (this.state.load === false && cookie.get('wup_cookie') === '1') {
            this.setState({
                load: true
            });
        }
    }

}

export default withRouter(Map);
