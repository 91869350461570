import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';

import LazyImage from './lazyImage.class';

import logoRock from "../assets/img/logos/logo_fels_hell.svg";
import logoHydro from "../assets/img/logos/logo_hydro_hell.svg";
import logoGeo from "../assets/img/logos/logo_geo_hell.svg";
import marked from "./markdown";


class Start extends Component {

    constructor(props) {
        super(props);

        this.state = {
            current: 0,
            prev: null,
            animation:'next',
            delay:10000
        };

        this.timeout = null;

        this.goTo = this.goTo.bind(this);

        this.parallax();
        this.animate();

        this.startHeadline = this.props.data.filter(d => d['typ'] === 'start_ueberschrift')[0];

        this.slides = this.props.data.filter(d => d['typ'] === 'start_bild');
        this.slides = this.shuffle(this.slides);


        this.video = this.props.data.filter(d => d['typ'] === 'start_video')[0];

        this.welcomeHeadline = this.props.data.filter(d => d['typ'] === 'willkommen_uberschrift')[0];
        this.welcomeText = this.props.data.filter(d => d['typ'] === 'willkommen_text')[0];
    }

    parallax() {
        let _self = this;

        window.addEventListener('scroll', () => {
            let topDistance = window.scrollY;
            let layers = document.querySelectorAll("[data-parallax='true']");

            layers.forEach(layer => {
                let depth = layer.getAttribute('data-depth');
                let movement = -(topDistance * depth);
                let translate3d = 'translate3d(0, ' + movement + 'px, 0)';

                layer.style['-webkit-transform'] = translate3d;
                layer.style['-moz-transform'] = translate3d;
                layer.style['-ms-transform'] = translate3d;
                layer.style['-o-transform'] = translate3d;
                layer.style.transform = translate3d;

                return true;
            });

            if (!!_self.textBox) {
                _self.textBox.style.opacity = 1 - topDistance / (_self.boxHeight/3);
            }

        });
    }

    animate() {

        if (!!this.timeout) {
            clearTimeout(this.timeout);
        }

        this.timeout = setTimeout(() => {
            this.goTo();
            this.animate();
        }, this.state.delay);

    }

    goTo() {
        if (!!this.timeout) {
            clearTimeout(this.timeout);
        }

        let len = this.props.data.filter(d => d['typ'] === 'start_bild').slice(0, 5).length;
        let current, prev;


        prev = this.state.current;

        if (this.state.current === len-1) {
            current = 0;
        } else {
            current = this.state.current + 1;
        }


        this.setState({
            current: current,
            prev: prev
        });

        setTimeout(() => {
            this.setState({
                prev: null
            });
        },400);

    }

    goToIndex(i) {
        if (!!this.timeout) {
            clearTimeout(this.timeout);
        }

        let prev = this.state.current,
            animation = 'next';

        if (i<prev) {
            animation = 'prev';

            this.setState({
                animation: 'stop prev'
            });
        }

        setTimeout(() => {
            this.setState({
                current: i,
                prev: prev,
                animation: animation
            });

            setTimeout(() => {
                this.setState({
                    prev: null,
                    animation: 'next'
                });

                this.animate();
            },400);
        },100);
    }

    /**
     * Shuffles array in place. ES6 version
     * @param {Array} a items An array containing the items.
     */
    shuffle(a) {
        for (let i = a.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [a[i], a[j]] = [a[j], a[i]];
        }
        return a;
    }

    render() {

        let path = this.props.location.pathname.replace('/', '');

        let logo;
        if (path === 'felsprojekt') {
            logo = logoRock;
        } else if (path === 'hydroprojekt') {
            logo = logoHydro;
        } else {
            logo = logoGeo;
        }

        return (
            <>
                <div id={this.props.id} className={'startBox'}>

                    <div className={'startBoxSlider'}>
                        <div className={'shape'} />

                        <div className={'plusBox'} data-depth={'0.30'} data-parallax={'true'}>
                            <div className={'plus'} />
                        </div>

                        <div className={'slides'}>

                            {!!this.slides && (!this.video || !this.video['Inhalt']) &&
                                <div className={'slidesBox ' + this.state.animation}>
                                    {
                                    this.slides.slice(0, 5).map((data, key) => {

                                            let cssClass = '';

                                            if (key === this.state.current) {
                                                cssClass = 'currentSlide'
                                            } else if (key === this.state.prev) {
                                                cssClass = 'prevSlide';
                                            }

                                            return (
                                                <div className={'slide ' + cssClass} key={'start_' + path + '_' + key}>
                                                    <div data-depth={'0.40'} data-parallax={'true'}>
                                                        <LazyImage dkey={'start_' + path + '_' + key} className={'img'} alt={'start_img_' + path + '_' + key} src={'image.php?file=' + process.env.PUBLIC_URL + data['Inhalt'] + '&height=700&width=1470'} width={1680} height={700} />
                                                    </div>
                                                </div>
                                            );
                                        })

                                    }

                                </div>
                            }

                            {this.video && this.video['Inhalt'] &&
                                <div className={'slide currentSlide'}>
                                    <video id="video" loop preload="auto" autoPlay playsInline async={true} muted src={`/assets${this.video['Inhalt']}`} type="video/mp4" />
                                </div>
                            }

                            <div className="textBox">

                                <div className={'logoBox'}>
                                    <img src={logo} className={'logo'} alt={'witt & partner Logo'} width={331} height={80} />
                                </div>

                                <h1 className="headline ">
                                    {this.startHeadline['Inhalt']}
                                </h1>
                            </div>

                        </div>

                        <div className={'bubbleBox'}>
                            {!!this.slides && !this.video &&
                            this.slides.slice(0, 5).map((data, key) => {
                                return <div key={'bubble_' + key} className={'bubble ' + (this.state.current === key ? 'current' : '')} onClick={() => this.goToIndex(key)} />;
                            })
                            }
                        </div>

                    </div>

                </div>

                <div className={'startWelcome'}>
                    <div className={'welcome'}>
                        <h2>
                            {this.welcomeHeadline['Inhalt']}
                        </h2>

                        <div className={'text'} dangerouslySetInnerHTML={{__html: marked(this.welcomeText['Inhalt'])}} />
                    </div>
                </div>
            </>
        );
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return (nextProps.data !== this.props.data || nextState !== this.state);
    }

    componentDidMount() {
        this.box = document.querySelector('.startBoxSlider');
        this.boxPositions = this.box.getBoundingClientRect();
        this.boxHeight = this.boxPositions.height;
        this.textBox = document.querySelector('.startBoxSlider .textBox');
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.data !== this.props.data && !!this.props.data) {
            if (!!this.timeout) {
                clearTimeout(this.timeout);
            }

            let current = document.querySelector('.startBox .currentSlide');
            let p = current.querySelector('div');
            let z = p && p.querySelector('div');

            if (!!current && z) {
                z.style.opacity = 0;
            }

            this.setState({
                current: 0,
                prev: null,
                animation:'stop',
            });

            setTimeout(() => {
                this.setState({
                    animation:'next',
                });

                this.animate();

                if (z) {
                    z.removeAttribute('style');
                }
            }, 1);

            this.box = document.querySelector('.startBoxSlider');
            this.boxPositions = this.box.getBoundingClientRect();
            this.boxHeight = this.boxPositions.height;
            this.textBox = document.querySelector('.startBoxSlider .textBox');

            this.startHeadline = this.props.data.filter(d => d['typ'] === 'start_ueberschrift')[0];

            this.slides = this.props.data.filter(d => d['typ'] === 'start_bild');

            this.video = this.props.data.filter(d => d['typ'] === 'start_video')[0];

            this.welcomeHeadline = this.props.data.filter(d => d['typ'] === 'willkommen_uberschrift')[0];
            this.welcomeText = this.props.data.filter(d => d['typ'] === 'willkommen_text')[0];

            this.slides = this.shuffle(this.slides);
        }
    }

}

export default withRouter(Start);
