import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import LazyImage from "./lazyImage.class";

// import '../assets/scss/imageGallery.scss';

import '../assets/scss/imageSliderTiles.scss';

class ImageSlider extends Component {

    constructor(props) {
        super(props);

        this.state = {
            current: !!props.current ? props.current : 0
        };

        this.goTo = this.goTo.bind(this);
        this.goToIndex = this.goToIndex.bind(this);

        this.interval = null;
    }


    goTo() {
        let images = document.querySelectorAll('#' + this.props.id + '.tabContainer .tab.current .imageGroup .img, #' + this.props.id + '.sliderBoxElm .slide.current .imageGroup .img');

        if (!!images && images.length) {

            let current = this.state.current,
            next;

            if (current + 1 <= (images.length - 1)) {
                next = current + 1;
            } else {
                next = 0;
            }

            this.setState({
                current: next
            });
        }

    }

    goToIndex(i) {
        clearInterval(this.interval);

        this.setState({
            current: i
        }, () => {
            this.interval = setInterval(e => {
                this.goTo();
            },5000);
        });

    }

    render() {

        let images = !!this.props.images ? this.props.images : [undefined];

        return (
            <div className={'imageBoxInner'}>
                <div className={'imagesBox slider'}>
                    <div className={'imageGroup'}>
                        {images.map((i, k) => {
                            return <div key={'large' + k} className={'img ' + (this.state.current === k ? 'current' : '')}>
                                      <LazyImage key={'big' + k} dkey={'popup_img'} alt={'popup_img'} src={'image.php?file=' + (!!i ? i.replace(/(\r\n|\n|\r)/gm, "") : 'undefined').trim() + '&height=500&width=800'} forceLoad={this.props.forceLoad} />
                                   </div>;
                        })}
                    </div>
                </div>

                <div className={'imageBubbleBox'}>
                    {(images.length > 1) && images.map((gr, k) => {
                        return <div key={'bubble_' + k} className={'bubble ' + ((k === this.state.current) ? 'current' : '')} onClick={() => this.goToIndex(k, this.props.data)} />;
                    })}
                </div>
            </div>
        );
    }

    mount() {
        if (!!this.props.images && this.props.images.length > 1) {
            clearInterval(this.interval);

            this.interval = setInterval(e => {
                this.goTo();
            },5000);
        }
    }

    componentDidMount() {
        this.mount();
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }
}

export default withRouter(ImageSlider);
