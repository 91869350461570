import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import LazyImage from "./lazyImage.class";

import '../assets/scss/imageGallery.scss';

class AboutImageGallery extends Component {

    constructor(props) {
        super(props);

        this.state = {
            current: !!props.current ? props.current : 0
        };

        this.goTo = this.goTo.bind(this);
        this.goToIndex = this.goToIndex.bind(this);

        this.interval = null;
    }


    goTo() {
        let images = document.querySelectorAll('.aboutContent .tab.current .imageGroup .img');

        if (!!images && images.length) {

            let current = this.state.current,
            next;

            if (current + 1 <= (images.length - 1)) {
                next = current + 1;
            } else {
                next = 0;
            }

            this.setState({
                current: next
            });
        }

    }

    goToIndex(i) {
        clearInterval(this.interval);

        this.setState({
            current: i
        }, () => {
            this.interval = setInterval(e => {
                this.goTo();
            },5000);
        });

    }

    render() {

        let images = !!this.props.images ? this.props.images : [undefined];

        return (
            <>
                <div className={'images'}>

                    <div className={'imageGroup'}>
                        {images.map((i, k) => {
                            return <div key={'large' + k} className={'img ' + (this.state.current === k ? 'current' : '')}>
                                      <LazyImage key={'big' + k} dkey={'popup_img'} alt={'popup_img'} src={'image.php?file=' + (!!i ? i.replace(/(\r\n|\n|\r)/gm, "") : 'undefined').trim() + '&height=1080&width=1920'} width={1920} height={1080} />
                                   </div>;
                        })}
                    </div>

                    <div className={'bubbleBox'}>
                        {(images.length > 1) && images.map((gr, k) => {
                            return <div key={'bubble_' + k} className={'bubble ' + ((k === this.state.current) ? 'current' : '')} onClick={() => this.goToIndex(k, this.props.data)} />;
                        })}
                    </div>

                </div>
            </>
        );
    }

    mount() {
        if (!!this.props.images && this.props.images.length > 1) {
            clearInterval(this.interval);

            this.interval = setInterval(e => {
                this.goTo();
            },5000);
        }
    }

    componentDidMount() {
        this.mount();
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }
}

export default withRouter(AboutImageGallery);
