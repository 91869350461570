import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import LazyImage from "./lazyImage.class";

import '../assets/scss/imageGallery.scss';


class ImageGallery extends Component {

    constructor(props) {
        super(props);

        this.state = {
            current: !!props.current ? props.current : 0
        };

        this.changeSection = this.changeSection.bind(this);
    }


    goTo(to) {
        let images = document.querySelectorAll('.imageGallery .img');


        if (!!images && images.length) {

            let current = this.state.current,
                next,
                prev;

            if (to === 'next') {

                if (current + 1 <= (images.length - 1)) {
                    next = current + 1;
                } else {
                    next = 0;
                }

                this.setState({
                    current: next
                });

            } else {

                if (current - 1 >= 0) {
                    prev = current - 1;
                } else {
                    prev = images.length-1;
                }

                this.setState({
                    current: prev,
                });
            }

        }

    }

    changeSection(i) {
        this.setState({
            current: i
        });
    }

    render() {

        let images = !!this.props.entry ? this.props.entry : [undefined];

        return (
            <>
                <div className={'imageGallery'}>

                    {(images.length > 1) &&
                        <>
                            <div className="prev icon-prev" onClick={() => this.goTo('prev')} />
                            <div className="next icon-next" onClick={() => this.goTo('next')} />
                        </>
                    }


                    <div className={'close icon-close'} onClick={() => this.props.closeGallery()} title={'Schließen'} />

                    <div className={'image'}>

                        <div className={'images'}>
                            {images.map((i, k) => {
                                return <div key={'large' + k} className={'img ' + (this.state.current === k ? 'current' : '')} onClick={() => this.props.closeGallery()} title={'Schließen'}>
                                        <LazyImage key={'big' + k} dkey={'popup_img'} alt={'popup_img'} src={'image.php?file=img/referenzen/' + (!!i ? i.replace(/(\r\n|\n|\r)/gm, "") : 'undefined').trim() + '&height=1080&width=1920'} width={1920} height={1080} />
                                       </div>;
                            })}
                        </div>

                        <div className={'position'}>
                            <div className={'subHeadline'}>
                                {this.props.headlines[this.state.current]}
                            </div>
                            <div>
                                {this.state.current+1}/{images.length}
                            </div>
                        </div>

                    </div>
                </div>
            </>
        );
    }
}

export default withRouter(ImageGallery);
