import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';

import '../assets/scss/referencePopup.scss';
import ReferencePopupContent from "./referencePopupContent.class";


class ReferencePopup extends Component {

    constructor(props) {
        super(props);

        let data = this.props.data;
        let current = null;

        data.forEach((d, k) => {
            if (d['unique'] === this.props['currentId']['unique']) {
                return current = k;
            }
        });

        this.state = {
            current: this.props['currentId']['unique'],
            className: '',

            section: this.props['currentId']['Geschäftsfeld'],
            key: current + 1
        };

        this.goTo = this.goTo.bind(this);
    }

    goTo(to) {
        let slides = document.querySelectorAll('.referencePopup .popupSlide');
        let slideBox = document.querySelector('.referencePopup');


        if (!!slides && slides.length) {

            let len = slides.length,
                current = document.querySelector('.referencePopup .popupSlide.current'),
                next,
                prev,
                elm;

            if (to === 'next') {

                if (!!current.nextSibling) {
                    next = current.nextSibling;
                } else {
                    next = slides[0];
                }

                next.classList.add('current');

                current.classList.add('prevSlide');

                elm = next;

                this.setState({
                    section: next.getAttribute('data-class'),
                    key: Array.prototype.indexOf.call(slides, next)+1
                });

            } else {

                if (!!current.previousSibling) {
                    prev = current.previousSibling;
                } else {
                    prev = slides[len-1];
                }

                prev.style.transition = 'none';
                prev.classList.add('prevSlide');

                setTimeout(() => {
                    prev.removeAttribute('style');
                    prev.classList.add('current');
                    prev.classList.remove('prevSlide');

                    current.classList.add('nextSlide');

                    elm = prev;

                    this.setState({
                        section: prev.getAttribute('data-class'),
                        key: Array.prototype.indexOf.call(slides, prev)+1
                    });
                }, 1);
            }


            setTimeout(() => {
                slideBox.classList.remove('gp', 'fp', 'hp');
                slideBox.classList.add(elm.getAttribute('data-class'));

                current.style.transition = 'none';
                current.classList.remove('current', 'nextSlide', 'prevSlide');
                setTimeout(() => {
                    current.removeAttribute('style');
                }, 200);
            }, 200);

        }

    }

    render() {
        return (
            <div className={'referencePopup'} onClick={() => this.props.popup(null)}>

                <div className={'popupContent'} onClick={(e) => {e.stopPropagation();}}>
                    {this.props.data.length > 1 &&
                        <>
                            <div className="prev icon-prev" onClick={() => this.goTo('prev')} />
                            <div className="next icon-next" onClick={() => this.goTo('next')} />
                        </>
                    }


                    <div className={'close icon-close'} onClick={() => this.props.popup(null)} title={'Schließen'} />

                    <div className={'referencePopupSlides'}>
                        {
                            this.props.data.length ? this.props.data.map((entry, key) => {
                                return (
                                    <div className={'popupSlide ' + (entry['unique'] === this.state.current ? 'current' : '')} key={'_' + key} data-key={entry['unique']}>
                                        <ReferencePopupContent entry={entry} activityArea={this.state.activityArea} key={'popContent_' + key} />
                                    </div>
                                )
                            }) : <></>
                        }

                    </div>

                    <div className={'page'}>
                        <div className={''}>
                            Projekt {this.state.key}/{this.props.data.length}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(ReferencePopup);
