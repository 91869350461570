import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import LazyImage from "./lazyImage.class";
import marked from "./markdown";
import ImageGallery from "./imageGallery.class";


class ReferencePopupContent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            current: 0,
            gallery: false
        };

        this.changeSection = this.changeSection.bind(this);

        this.scrollbox = React.createRef();

        this.positions = {};
    }

    getPositions() {

        if (!!this.refs.scrollbox) {
            let elms = this.refs.scrollbox.querySelectorAll('hr.pageBreak');

            if (!!elms && elms.length) {
                for (let i = 0; i < elms.length; i++) {
                    let el = elms[i];
                    this.positions[i] = {
                        pos: el.offsetTop,
                        elm: el
                    };
                }
            }

        }

    }

    scroll(e) {
        if (Object.keys(this.positions).length) {
            let elm = e.nativeEvent.target;
            let elTop = elm.querySelector('.blubb');
            let docViewTop = elm.scrollTop + elTop.offsetTop;
            let height = e.nativeEvent.target.getBoundingClientRect().height / 2;
            // let docViewBottom = docViewTop + height;

            Object.keys(this.positions).forEach((e,k) => {
                let el = this.positions[e].pos;

                if ((el <= (docViewTop + height + 40)) && (el >= (docViewTop + height - 20)) && this.state.current !== k && k !== 0) {
                    this.setState({
                        current: k
                    });
                } else if (elm.scrollTop <= 40 && this.state.current !== 0) {
                    this.setState({
                        current: 0
                    });
                }
            });
        }
    }

    changeSection(i) {
        this.setState({
            current: i
        });

        if (Object.keys(this.positions).length && !!this.positions[i] && !!this.positions[i].elm) {
            this.positions[i].elm.scrollIntoView({
                // behavior: 'smooth'
            });
        }

    }

    replacePages(text) {
        return text.replace(/\[img\]/g, '<hr class="pageBreak" />');
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.getPositions();
    }

    componentDidMount() {
        this.getPositions();
    }

    openMoreImages() {
        let breakCount = document.querySelectorAll('.popupSlide.current .pageBreak');
        let imageCount = this.images;

        if (imageCount.length > breakCount.length) {
            this.setState({
                current: breakCount.length,
                gallery: true
            })
        }

    }

    render() {
        let img = {
            gp: 'icon-boden',
            fp: 'icon-fels',
            hp: 'icon-wasser'
        };
        let title = {
            gp: 'Geoprojekt',
            fp: 'Felsprojekt',
            hp: 'Hydroprojekt'
        };

        this.images = !!!!this.props.entry && !!this.props.entry['Projektfotos'] ? this.props.entry['Projektfotos'].split(',') : [undefined];
        let headlines = !!!!this.props.entry && !!this.props.entry['Ueberschrift'] ? this.props.entry['Ueberschrift'].split(',') : [undefined];

        let imgs = 1;

        if (!!this.props.entry['Projektinfos']) {
            imgs = (this.props.entry['Projektinfos'].match(/\[img\]/g) || []).length + 1;
        }

        return (
            <>
                {(this.state.gallery && this.images.length) &&
                    <ImageGallery current={this.state.current} entry={this.images} headlines={headlines} visible={this.state.gallery} closeGallery={e => this.setState({gallery: false})} />
                }

                <div className={'section'}>
                    {this.props.entry['Geschäftsfeld'].split(',').map((s,k) => {
                        return <span key={k}>
                                    <span key={'img' + k} className={'sectionIcon ' + img[s.trim()]} title={title[s.trim()]} />
                                    <span key={'text' + k} className={'text'}>{title[s.trim()]}</span>
                                </span>
                    })}
                </div>

                <div className={'imageBox'}>

                    <div className={'image'}>

                        <div className={'images'}>
                            {this.images.map((i, k) => {
                                return <div key={'large' + k} className={'img ' + (this.state.current === k ? 'current' : '')} onClick={e => this.setState({gallery:true})} title={'Vollbild'}>
                                           <LazyImage key={'big' + k} dkey={'popup_img'} alt={'popup_img'} src={'image.php?file=img/referenzen/' + (!!i ? i.replace(/(\r\n|\n|\r)/gm, "") : 'undefined').trim() + '&height=560&width=1000'} width={1000} height={560} />
                                       </div>;
                            })}
                        </div>

                        <div className={'thumbnails'}>
                            {this.images.length > 1 && this.images.map((i, k) => {
                                return <div key={'thumb' + k} className={'img ' + (this.state.current === k ? 'current' : '')} onClick={() => this.changeSection(k)}>
                                    <LazyImage key={'small' + k} dkey={'popup_img'} alt={'popup_img'} src={'image.php?file=img/referenzen/' + (!!i ? i.replace(/(\r\n|\n|\r)/gm, "") : 'undefined').trim() + '&height=80&width=100'} width={100} height={80} />
                                </div>;
                            })}
                        </div>

                    </div>
                </div>

                <div className="textBox">
                    <div className="textBoxInner textScrollBox" onScroll={e => this.scroll(e)} ref={'scrollbox'}>
                        <div className={'blubb'}>
                            <hr className="pageBreak"/>

                            <h4 className="headline">
                                {this.props.entry['Projektbezeichnung']}
                            </h4>

                            <div className={'detailsTop detailsOnTop'}>
                                <div className={'left'}>
                                    <div>
                                        {this.props.entry['Kurzbeschreibung']}
                                    </div>
                                </div>

                                {!!this.props.entry['Projektzeitraum'] &&
                                    <div className={'right'}>
                                        <strong>Projektzeitraum:</strong>
                                        <div>{this.props.entry['Projektzeitraum']}</div>
                                    </div>
                                }
                            </div>

                            <div className={'detailsTop'}>
                                <div className={'left'}>
                                    <strong>
                                        Auftraggeber:
                                    </strong>
                                    <div>
                                        {this.props.entry['AG']}
                                    </div>
                                    <div>
                                        {this.props.entry['Ort']}
                                    </div>
                                </div>

                                {!!this.props.entry['Projektvolumen'] &&
                                <div className={'right'}>
                                    <strong>{this.props.activityArea === 'fe' ? 'Fördersumme':'Projektvolumen'}:</strong>
                                    <div>{((!!this.props.entry['Projektvolumen'] && !this.props.entry['Projektvolumen'].includes('?')) ? new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(this.props.entry['Projektvolumen']) : this.props.entry['Projektvolumen'])}</div>
                                </div>
                                }
                            </div>

                            {(!!this.props.entry && !!this.props.entry['Projektinfos']) &&
                                <div className="text" dangerouslySetInnerHTML={{__html: this.replacePages(marked(this.props.entry['Projektinfos']))}} />
                            }

                            {(imgs < this.images.length) &&
                                <div className={'button'} onClick={e => this.openMoreImages()}>
                                    Mehr Bilder anzeigen
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </>
        );
    }


}

export default withRouter(ReferencePopupContent);
