import React, {Component} from 'react';
import cookie from 'cookiejs';

import '../assets/scss/cookieConsent.scss';

class CookieConsent extends Component {

    abort () {
        this.props.hideCookieConsent();

        if (!!cookie.get('wup_cookie')) {
            this.props.abortCookies();
        }

        cookie.set('wup_cookie', '0');
    }

    accept() {
        cookie.remove('ga-disable-UA-165941736-1');
        cookie.set('wup_cookie', '1');
        this.props.hideCookieConsent();
    }
    render() {
        return (
            <div className={'wup_cks overlay'}>
                <div className={'content'}>
                    <h3>Ihre Privatsphäre ist uns wichtig!</h3>

                    <p>
                        Wir verwenden Cookies und andere Technologien für den Betrieb unserer Webseite.
                        Mit Ihrer Zustimmung verwenden wir zudem auch optionale Cookies zu Analysezwecken und für eine verbesserte Nutzererfahrung.
                    </p>

                    <div className={'buttonBox'}>
                        <button className={'button'} onClick={() => this.abort()}>Ablehnen</button>
                        <button className={'button'} onClick={() => this.accept()}>Zustimmen</button>
                    </div>
                </div>
            </div>
        );
    }
}

export default CookieConsent;