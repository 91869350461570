import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';

import LazyLoad, {forceVisible} from 'react-lazy-load';


class LazyImage extends Component {

    load(e, img) {
        let target = this.refs[this.props.dkey];
        // let img = e.target.getAttribute('src');

        setTimeout(() => {
            if (!!target) {
                target.classList.add('loaded');
                target.style.backgroundImage = 'url(' + img + ')';
            }
        }, 200);
    }

    render() {

        let path = '';
        if (process.env.NODE_ENV === 'development') {
            path = window.location.protocol + '//' + window.location.hostname + '/wup/'
        }

        return (
            <LazyLoad onContentVisible={(e) => this.load(e, path + this.props.src + '&.jpg')}>
                <div ref={this.props.dkey} className={'lazyLoad'} />
            </LazyLoad>
        )

    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if (nextProps.forceLoad !== this.props.forceLoad && !!nextProps.forceLoad) {
            // forceVisible();
        }

        return this.props.src !== nextProps.src && !!nextProps.src;
    }

}

export default withRouter(LazyImage);
