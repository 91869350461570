import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';

import LazyImage from './lazyImage.class';
import marked from "./markdown";


class Sections extends Component {

    constructor(props) {
        super(props);

        this.goTo = this.goTo.bind(this);
        this.open = this.open.bind(this);

        this.refLink = {}
    }

    goTo(to) {
        let current;

        let currentSlide = document.querySelector('.sectionsBox .sectionsBoxSlider .slide.currentSlide');

        if (!!currentSlide) {

            let slides = document.querySelectorAll('.sectionsBox .sectionsBoxSlider .slide');
            let key = parseInt(currentSlide.getAttribute('data-key'));

            if (to === 'next') {
                if (key === (slides.length - 1)) {
                    current = 0;
                } else {
                    current = key + 1;
                }

                currentSlide.classList.add('prevSlide');
                currentSlide.classList.remove('currentSlide');

                setTimeout(() => {
                    currentSlide.classList.remove('prevSlide');
                }, 400);

                slides[current].classList.add('currentSlide');
            } else {
                if (key === 0) {
                    current = slides.length - 1;
                } else {
                    current = key - 1;
                }

                currentSlide.classList.add('nextSlide');
                currentSlide.classList.remove('currentSlide');

                setTimeout(() => {
                    currentSlide.classList.remove('nextSlide');
                }, 400);

                slides[current].style.transition = 'none';
                slides[current].style.left = '-100%';

                setTimeout(() => {
                    slides[current].removeAttribute('style');
                    slides[current].classList.add('currentSlide');
                }, 1);
            }

            setTimeout(() => {
                let ref = this.refs['slide_' + current];
                let target = ref.getAttribute('data-target');

                this.props.history.push(target);
            }, 400)

        }

    }

    open(section, id, activityArea) {
        this.refLink = {
            businessArea: section,
            activityArea: activityArea
        };

        let container = document.querySelector('.sectionsBox .subLayerContainer');
        container.classList.add('visible');

        let box = this.refs['subLayer_' + section];
        box.style.transition = 'none';
        box.classList.add('currentSlide');

        let slide = this.refs['subLayerSlide_' + section + '_' + id];
        let slideChild = slide.querySelector('.subLayerSlideContent');
        slideChild.style.transition = 'none';
        slide.classList.add('currentSlide');

        setTimeout(() => {
            box.removeAttribute('style');
            slideChild.removeAttribute('style');
        },200);

    }

    subLayerGoTo(to) {
        let current;

        let currentLayer = document.querySelector('.sectionsBoxSlider .subLayerContainer .subLayer.currentSlide');
        let currentSlide = document.querySelector('.sectionsBoxSlider .subLayerContainer .subLayer.currentSlide .subLayerSlide.currentSlide');

        if (!!currentSlide) {

            let slides = document.querySelectorAll('.sectionsBoxSlider .subLayerContainer .subLayer.currentSlide .subLayerSlide');
            let key = parseInt(currentSlide.getAttribute('data-key'));

            if (to === 'next') {
                if (key === slides.length) {

                    currentSlide.classList.add('prevSlide');

                    let child = currentLayer.firstChild;
                    child.classList.add('currentSlide');

                    this.refLink = {
                        businessArea: currentLayer.getAttribute('data-key'),
                        activityArea: child.getAttribute('data-activityarea')
                    };

                    setTimeout(() => {
                        currentSlide.classList.remove('currentSlide');
                        currentSlide.classList.remove('prevSlide');
                        // currentLayer.classList.remove('prevSlide');
                    },400);

                } else {
                    current = key;

                    currentSlide.classList.add('prevSlide');
                    currentSlide.classList.remove('currentSlide');

                    setTimeout(() => {
                        currentSlide.classList.remove('prevSlide');
                    }, 400);

                    slides[current].classList.add('currentSlide');

                    this.refLink = {
                        businessArea: currentLayer.getAttribute('data-key'),
                        activityArea: slides[current].getAttribute('data-activityarea')
                    };
                }


            } else {
                let prev;

                if (key === 1) {
                    prev = currentLayer.lastChild;
                } else {
                    prev = currentSlide.previousSibling;
                }

                currentSlide.classList.add('nextSlide');
                currentSlide.classList.remove('currentSlide');

                setTimeout(() => {
                    currentSlide.classList.remove('nextSlide');
                }, 400);

                let con = prev.querySelector('.subLayerSlideContent');

                con.style.transition = 'none';
                con.style.left = '-100%';

                setTimeout(() => {
                    con.removeAttribute('style');
                    prev.classList.add('currentSlide');

                    this.refLink = {
                        businessArea: currentLayer.getAttribute('data-key'),
                        activityArea: prev.getAttribute('data-activityarea')
                    };
                }, 1);

            }

        }
    }

    close() {
        let container = document.querySelector('.sectionsBox .subLayerContainer');
        container.classList.remove('visible');

        let currentLayer = document.querySelectorAll('.subLayerContainer .subLayer.currentSlide');
        currentLayer.forEach(elm => {
            elm.classList.remove('currentSlide');
        });

        let currentSlide = document.querySelectorAll('.subLayerContainer .subLayer .subLayerSlide.currentSlide');
        currentSlide.forEach(elm => {
            elm.classList.remove('currentSlide');
        });
    }

    render() {

        let path = this.props.location.pathname.replace('/', '');
        let section;

        if (path === '' || path === 'geoprojekt') {
            section = 'gp';
        } else if (path === 'felsprojekt') {
            section = 'fp';
        } else if (path === 'hydroprojekt') {
            section = 'hp';
        }

        let childSlide = {};

        return (
            <div id={this.props.id} className={'sectionsBox'}>

                <div className={'sectionsBoxSlider'}>
                    <div className={'horizontalLine'}/>

                    <div className={'slides'}>

                        <div className={'prev icon-prev'} onClick={() => this.goTo('prev')}/>

                        <div className={'slidesBox'}>

                            {!!this.props.data &&

                            Object.keys(this.props.data).map((d, key) => {
                                let data = this.props.data[d];

                                let cssClass = '';

                                if (d === section || (d === 'gp' && section === '')) {
                                    cssClass = 'currentSlide'
                                }

                                let long;
                                if (d === 'gp') {
                                    long = 'geoprojekt';
                                } else if (d === 'fp') {
                                    long = 'felsprojekt';
                                } else if (d === 'hp') {
                                    long = 'hydroprojekt';
                                }

                                childSlide[d] = {};

                                let headline = data.filter(t => t['Typ'] === 'Geschäftsfeld_Überschrift')[0];
                                let items = data.filter(t => t['Typ'] === 'Tätigkeitsfeld');

                                if (!!items) {
                                    return (
                                        <div ref={'slide_' + key} key={'slide_' + path + '_' + key} className={'slide ' + cssClass} data-target={long} data-key={key}>
                                            <h2>{headline['Überschrift']}</h2>

                                            <div className={'slideContent'}>
                                                <div className={'iconContent'}>
                                                    {
                                                        items.map((item, subkey) => {
                                                            childSlide[d][item['Tätigkeitsfeld']] = {
                                                                activityArea: item['Tätigkeitsfeld'],
                                                                section: item['Überschrift'],
                                                                icon: item['Icon'],
                                                                description: item['Text'],
                                                                image: item['Bild'],
                                                            };

                                                            return (
                                                                <div key={'item_' + subkey} className={'item'}>
                                                                    <div className={'itemContent'} onClick={() => this.open(d, item['Tätigkeitsfeld'], item['Tätigkeitsfeld'])}>
                                                                        <div className={'icon ' + item['Icon']}/>
                                                                        <div className={'name'}>
                                                                            {item['Überschrift']}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }


                                                </div>
                                            </div>
                                        </div>

                                    );
                                } else {
                                    return '';
                                }

                            })

                            }


                        </div>

                        <div className={'next icon-next'} onClick={() => this.goTo('next')}/>

                        <div className={'subLayerContainer'}>
                            <div className={'prev icon-prev'} onClick={() => this.subLayerGoTo('prev')} />
                            <div className={'close icon-close'} onClick={() => this.close()}/>

                            <div className={'buttonBox'}>
                                <button className={''} onClick={() => this.props.openReference(this.refLink.businessArea, this.refLink.activityArea)}>
                                    Zur Referenzliste
                                </button>

                                <button className={''} onClick={() => this.props.openPublications(this.refLink.businessArea, this.refLink.activityArea)}>
                                    Veröffentlichungen
                                </button>
                            </div>

                            <div className={'subLayers'}>
                                {
                                    Object.keys(childSlide).map((key) => {
                                        return (
                                            <div ref={'subLayer_' + key} key={'subLayer_' + key} className={'subLayer'} data-key={key}>
                                                {
                                                    Object.keys(childSlide[key]).map((skey, k) => {
                                                        let subData = childSlide[key][skey];
                                                        return (
                                                            <div ref={'subLayerSlide_' + key + '_' + skey} key={'subLayerSlide_' + skey} className={'subLayerSlide'} data-key={k+1} data-activityarea={subData.activityArea}>
                                                                <div className="horizontalLine" />

                                                                <div className={'subLayerSlideContent'}>
                                                                    <div className={'context'}>
                                                                        <div className={'top'}>
                                                                            <div className={'icon ' + subData['icon']}/>
                                                                            <h2 className={'title'}>
                                                                                Bereich<br/>
                                                                                {subData['section']}
                                                                            </h2>
                                                                        </div>

                                                                        <div className={'description'} dangerouslySetInnerHTML={{__html: marked(subData['description'])}} />

                                                                    </div>
                                                                </div>
                                                                <div className={'imgBox'}>
                                                                    <LazyImage dkey={'slider_img_' + key + '_' + skey} className={'img'} alt={'slider_img_' + path + '_' + skey} src={'image.php?file=' + subData.image + '&height=700&width=1000'} width={1000} height={700} />
                                                                </div>
                                                            </div>
                                                        );
                                                    })
                                                }
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <div className={'next icon-next'} onClick={() => this.subLayerGoTo('next')} />
                        </div>
                    </div>

                </div>

                <div className={'horizontalLine'}/>
            </div>
        );
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if (nextProps.resetAll !== this.props.resetAll && !!nextProps.resetAll) {
            this.close();
        }

        return (nextProps.data !== this.props.data || nextState !== this.state || nextProps.location.pathname !== this.props.location.pathname)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.path !== this.props.path) {
            this.params = this.props.location.pathname.replace('/', '');

            this.close();
        }
    }

}

export default withRouter(Sections);
