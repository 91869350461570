import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';

import LazyImage from "./lazyImage.class";
import ReferencePopup from './referencePopup.class';
import PdfDownload from './pdfDownload.class';


class References extends Component {

    constructor(props) {
        super(props);

        this.state = {
            businessArea: '',
            service: !!this.props.service ? this.props.service : '',
            activityArea: '',
            search: '',

            json: this.props.data,
            filtered: [],
            references: [],
            publications: [],

            sortBy: 'Jahr',
            sort: 'desc',

            current: 'references',

            popup: null,
            showDownload: false,
            mounted: false,
            maxEntries: 10
        };

        this.show = this.show.bind(this);
        this.parse = this.parse.bind(this);
        this.change = this.change.bind(this);
        this.changeArea = this.changeArea.bind(this);
        this.filterEntries = this.filterEntries.bind(this);
        this.changeSort = this.changeSort.bind(this);
        this.showMore = this.showMore.bind(this);
        this.showLess = this.showLess.bind(this);
        this.resetMore = this.resetMore.bind(this);
        this.popup = this.popup.bind(this);
        this.reset = this.reset.bind(this);
        this.closeAndUnbind = this.closeAndUnbind.bind(this);
        this.toggleDownload = this.toggleDownload.bind(this);

        this.businessArea = '';

        this.downloadRef = React.createRef();
    }

    reset() {
        if (this.params === '' || this.params === 'geoprojekt') {
            this.businessArea = 'gp';
        } else if (this.params === 'felsprojekt') {
            this.businessArea = 'fp';
        } else if (this.params === 'hydroprojekt') {
            this.businessArea = 'hp';
        }

        this.setState(this.resetState);

        this.props.updateParent('businessArea', this.businessArea);
        this.props.updateParent('activityArea', '');
        this.props.updateParent('service', '');
        this.props.updateParent('search', '');
    }

    parse(key) {
        let file = this.props.data;

        return file[key];
    }

    mount() {

        if (this.params === '' || this.params === 'geoprojekt') {
            this.businessArea = 'gp';
        } else if (this.params === 'felsprojekt') {
            this.businessArea = 'fp';
        } else if (this.params === 'hydroprojekt') {
            this.businessArea = 'hp';
        }

        let _json = this.parse('RL');
        let _jsonPub = this.parse('VO');

        _json = Object.keys(_json).map((e, k) => {
            return Object.assign({}, _json[k], {
                unique : '_' + k
            });
        });

        let filtered = _json.filter(entry => {
            return entry['Geschäftsfeld'].includes(this.businessArea);
        });

        let references = _json.filter(entry => {
            return entry['Geschäftsfeld'].includes(this.businessArea) && !!entry['Projektinfo'] && !entry['Projektinfo'].includes('#');
        });

        let publications = _jsonPub;

        filtered.sort((a, b) => (b[this.state.sortBy] > a[this.state.sortBy]) ? 1 : -1);
        references.sort((a, b) => (b[this.state.sortBy] > a[this.state.sortBy]) ? 1 : -1);
        publications.sort((a, b) => (b[this.state.sortBy] > a[this.state.sortBy]) ? 1 : -1);

        this.setState({
            filtered: filtered,
            references: references,
            publications: publications,
            businessArea: this.businessArea,
            service: this.props.service,
            activityArea: this.props.activityArea,
            search: this.props.search,
            mounted: true
        });
    }

    highlight(text) {
        if(this.props.search.length > 1) {

            let split = this.props.search.split('+').filter(e => e !== '');
            let re = new RegExp((split.length > 1 ? '(' + split.join('|') + ')' : split), 'igm');
            // let re = new RegExp('('+this.props.search+')', 'ig');

            if (!!text) {
                return text.replace(re, '<span class="textSearchHighlight">$&</span>');
            } else {
                return text;
            }
        }
        else return text;
    }

    search(e) {
        let target = e.target;

        this.props.updateParent('search', target.value);
    }

    change(e) {
        let target = e.target;

        if (target.name) {
            // this.filterEntries(target.name, target.value);
            this.props.updateParent(target.name, target.value);
        }
    }

    changeArea(e) {
        let target = e.target;

        this.setState({
            maxEntries: 10
        });

        if (target.name) {
            // this.filterEntries(target.name, target.value);
            this.props.updateParent(target.name, target.value);
        }
    }

    changeSort(type, sort) {
        this.filterEntries('sort', (sort === 'asc') ? 'desc': 'asc', type);
    }

    filterEntries(key, value, type) {

        let _json = this.parse('RL');
        let _jsonPub = this.parse('VO');
        // let _jsonDetails = this.parse('RP');

        _json = Object.keys(_json).map((e, k) => {
            return Object.assign({}, _json[k], {
                unique : '_' + k
            });
        });

        let filter = {
            businessArea: this.props.businessArea,
            service: this.props.service,
            activityArea: this.props.activityArea,
            search: !!this.props.search ? this.props.search : '',
            sort: this.state.sort
        };

        if (!!key && !!value) {
            filter[key] = value;
        }

        let json = _json.filter(entry => {
            return entry['Geschäftsfeld'].includes(filter.businessArea) &&
                   entry['Leistung'].includes(filter.service) &&
                   entry['Tätigkeitsfeld'].includes(filter.activityArea);
        });

        let split = filter.search.split('+').filter(e => e !== '');
        let search = new RegExp((split.length > 1 ? '(?=.*' + split.join(')(?=.*') + ')' : split), 'gmi');

        let filtered = json.filter(item => {
            return (Object.values(item).filter(i => {
                return i.match(search);
            })).length;
        });

        let publications = _jsonPub
            .filter(entry => {
                return (!!entry['Geschäftsfeld'] && entry['Geschäftsfeld'].includes(filter.businessArea)) || !entry['Geschäftsfeld'];
            })
            .filter(item => {
            return (Object.values(item).filter(i => {
                return i.match(search);
            })).length;
        });

        let references = filtered.filter(item => {
            return !!item['Projektinfo'] && !item['Projektinfo'].includes('#');
        }).map(r => {
            // let filter;

            // if (!!r['Projektinfo'] && !r['Projektinfo'].includes('#')) {
            //     filter = _jsonDetails.filter(entry => {
            //         return entry['ID'] === r['Projektinfo'];
            //     });
            //
            //     if (!!filter[0]) {
            //         Object.assign(r, filter[0])
            //     }
            // }

            r['Projektvolumen'] = !!r['Projektvolumen'] ? r['Projektvolumen'].replace('???', '') : '';
            return r;
        });

        let _sortType = !!type ? type : this.state.sortBy;

        if (filter.sort === 'asc') {
            if (_sortType === 'Jahr') {
                if (filtered.length) {
                    filtered.sort((a, b) => (a[_sortType] > b[_sortType]) ? 1 : -1);
                }

                if (references.length) {
                    references.sort((a, b) => (a[_sortType] > b[_sortType]) ? 1 : -1);
                }

                if (publications.length) {
                    publications.sort((a, b) => {
                        let aa = a[_sortType].split('/').reverse().join(),
                            bb = b[_sortType].split('/').reverse().join();

                        return aa > bb ? 1 : -1
                    });
                }
            } else if (_sortType === 'Projektvolumen') {
                if (references.length) {
                    references.sort((a, b) => {
                        let _a = !!a[_sortType] ? parseInt(a[_sortType]) : 0;
                        let _b = !!b[_sortType] ? parseInt(b[_sortType]) : 0;

                        return (_a > _b) ? 1 : -1
                    });
                }
            } else {
                if (!!filtered && filtered.length && filtered[0].hasOwnProperty(_sortType)) {
                    filtered.sort((a, b) => (a[_sortType].localeCompare(b[_sortType])));
                }

                if (references.length && references[0].hasOwnProperty(_sortType)) {
                    references.sort((a, b) => (a[_sortType].localeCompare(b[_sortType])));
                }

                if (publications.length && publications[0].hasOwnProperty(_sortType)) {
                    publications.sort((a, b) => (a[_sortType].localeCompare(b[_sortType])));
                }
            }
        } else {
            if (_sortType === 'Jahr') {
                if (!!filtered && filtered.length) {
                    filtered.sort((a, b) => (b[_sortType] > a[_sortType]) ? 1 : -1);
                }

                if (references.length) {
                    references.sort((a, b) => (b[_sortType] > a[_sortType]) ? 1 : -1);
                }

                if (publications.length) {
                    publications.sort((a, b) => {
                        let aa = a[_sortType].split('/').reverse().join(),
                            bb = b[_sortType].split('/').reverse().join();

                        return bb > aa ? 1 : -1;
                    });
                }
            } else if (_sortType === 'Projektvolumen') {
                if (references.length) {
                    references.sort((a, b) => {
                        let _a = !!a[_sortType] ? parseInt(a[_sortType]) : 0;
                        let _b = !!b[_sortType] ? parseInt(b[_sortType]) : 0;

                        return (_b > _a) ? 1 : -1
                    });
                }
            } else {
                if (!!filtered && filtered.length && filtered[0].hasOwnProperty(_sortType)) {
                    filtered.sort((a, b) => (b[_sortType].localeCompare(a[_sortType])));
                }

                if (references.length && references[0].hasOwnProperty(_sortType)) {
                    references.sort((a, b) => (b[_sortType].localeCompare(a[_sortType])));
                }

                if (publications.length && publications[0].hasOwnProperty(_sortType)) {
                    publications.sort((a, b) => (b[_sortType].localeCompare(a[_sortType])));
                }
            }
        }

        // this.props.updateParent();

        this.setState({
            [key]: value,
            filtered: filtered,
            references: references,
            publications: publications,
            sortBy: _sortType
        })
    }

    clear() {
        this.props.updateParent('search', '');
    }

    show(tab) {
        if (tab === 'publications') {
            this.setState({
                current: tab,
                sortBy: 'Jahr',
                sort: 'desc',
                service: this.props.service,
                activityArea: this.props.activityArea,
                maxEntries: 10
            }, () => {
                this.filterEntries('sort', 'desc', 'Jahr');
            });
        } else {
            this.setState({
                current: tab,
                maxEntries: 10
            }, () => {
                this.filterEntries('sort', 'desc', 'Jahr');
            });
        }

        this.props.setTab(tab);

    }

    popup(entry, key) {
        if (!!entry) {
            this.setState({
                popup: entry
            });

            document.querySelector('body').classList.add('noscroll');
        } else {
            this.setState({
                popup: null
            });
            document.querySelector('body').classList.remove('noscroll');
        }
    }

    showMore() {
        this.setState({
            maxEntries: this.state.maxEntries + 10
        });
    }

    showLess(to) {
        this.setState({
            maxEntries: this.state.maxEntries - 10
        }, e => {
            this.props.clickAndScroll('#referenzen ' + to, true);
        });
    }

    resetMore() {
        this.setState({
            maxEntries: 10
        }, e => {
            this.props.clickAndScroll('#referenzen');
        });
    }

    handler = (e) => {

        let container = this.downloadRef.current;

        if (this.state.showDownload) {
            if (e.type === 'click') {
                if (!!container && !container.contains(e.target)) {
                    this.closeAndUnbind();
                }
            }
        }
    };

    closeAndUnbind = () => {
        this.setState({showDownload: false});
        document.removeEventListener('click', this.handler);
    };

    toggleDownload() {
        if (this.state.showDownload) {
            this.setState({
                showDownload: false
            });
            this.closeAndUnbind();
        } else {
            this.setState({
                showDownload: true
            });

            document.addEventListener('click', this.handler);
        }
    }

    getIcons(list) {
        if (!list) return <span />;

        let l = list.split(',').map(i => i.trim());
        let arr = [];

        let img = {
            gp: 'icon-boden',
            fp: 'icon-fels',
            hp: 'icon-wasser'
        };
        let title = {
            gp: 'Geoprojekt',
            fp: 'Felsprojekt',
            hp: 'Hydroprojekt'
        };

        if (!!this.props.businessArea) {
            arr.push(this.props.businessArea);
            let filter = l.filter(i => i !== this.props.businessArea);

            arr = [...arr, ...filter];
        } else {
            arr = l;
        }

        return arr.map(s => {
            return <span key={s} className={'sectionIcon ' + img[s]} title={title[s]} />
        });
    }

    render() {

        let year;
        let sections;

        if (!!this.props['sections'] && !!this.props.businessArea) {
            sections = this.props['sections']['groups'][this.props.businessArea].filter(t => t['Typ'] === 'Tätigkeitsfeld').map(i => {
                return {
                    value: i['Tätigkeitsfeld'],
                    text: i['Überschrift']
                }
            });
        } else if (!!this.props['sections'] && !this.props.businessArea) {

            let _sections = {};
            Object.keys(this.props['sections']['groups']).forEach(_i => {
                let i = this.props['sections']['groups'][_i].filter(t => t['Typ'] === 'Tätigkeitsfeld');
                i.forEach(s => {
                    _sections[s['Tätigkeitsfeld']] = {
                        value: s['Tätigkeitsfeld'],
                        text: s['Überschrift']
                    }
                });
            });

            sections = Object.keys(_sections).map(i => {
                return _sections[i];
            });
        }

        return (
            <div id={this.props.id} className={'referenceList'}>

                <h2>Referenzen & Veröffentlichungen</h2>

                <ul className={'tabs'}>
                    <li className={'tab ' + (this.state.current === 'references' ? 'current' : '')} onClick={() => this.show('references')}>
                        Referenzlisten

                        <div className={'bubble'}>
                            {this.state.filtered.length}
                        </div>
                    </li>
                    <li className={'tab ' + (this.state.current === 'projects' ? 'current' : '')} onClick={() => this.show('projects')}>
                        Referenzprojekte

                        <div className={'bubble'}>
                            {this.state.references.length}
                        </div>
                    </li>
                    <li className={'tab ' + (this.state.current === 'publications' ? 'current' : '')} onClick={() => this.show('publications')}>
                        Veröffentlichungen

                        <div className={'bubble'}>
                            {this.state.publications.length}
                        </div>
                    </li>
                </ul>

                <div className={'filter'}>

                    <div>
                        <div className={'sBox'}>
                            <select name={'businessArea'} value={this.props.businessArea} placeholder={'Alle Geschäftsfelder'} onChange={e => this.changeArea(e)} /*disabled={this.state.current === 'publications'}*/>
                                <option value={''}>Alle Geschäftsfelder</option>
                                <option value={'gp'}>Geoprojekte</option>
                                <option value={'fp'}>Felsprojekte</option>
                                <option value={'hp'}>Hydroprojekte</option>
                            </select>
                        </div>
                    </div>

                    <div>
                        <div className={'sBox ' + (this.state.current === 'publications' ? 'disabled' : '')}>
                            <select name={'service'} value={this.props.service} placeholder={'Alle Dienstleistungen'} onChange={e => this.change(e)} disabled={this.state.current === 'publications'}>
                                <option value={''}>Alle Dienstleistungen</option>
                                <option value={'be'}>Beratung</option>
                                <option value={'pl'}>Planung</option>
                                <option value={'pr'}>Prüfung</option>
                            </select>
                        </div>
                    </div>

                    <div>
                        <div className={'sBox ' + (this.state.current === 'publications' ? 'disabled' : '')}>
                            <select name={'activityArea'} value={this.props.activityArea} placeholder={'Alle Tätigkeitsfelder'} onChange={e => this.props.setActivityArea(e.target.value)} disabled={this.state.current === 'publications'}>
                                <option value={''}>Alle Tätigkeitsfelder</option>
                                {(!!sections && sections) &&
                                    sections.map((s, k) => {
                                        return <option key={k} value={s.value}>{s.text}</option>
                                    })
                                }
                            </select>
                        </div>
                    </div>

                    <div className={'searchBox'}>
                        <input name={'search'} type={'text'} value={this.props.search} placeholder={'Schlagwortsuche'} onChange={e => this.search(e)} />
                        {!!this.props.search &&
                            <div className={'clear icon-close'} onClick={() => this.clear()} title={'Löschen'} />
                        }
                    </div>

                    {this.state.mounted &&
                        <div className={'dl'} ref={this.downloadRef}>
                            <div className={'downloadBtn icon-more'} onClick={() => this.toggleDownload()} />
                            {this.state.showDownload &&
                                <PdfDownload filtered={{
                                    references: this.state.filtered,
                                    projects: this.state.references,
                                    publications: this.state.publications
                                }} allEntries={{
                                    references: {
                                        gp: this.parse('RL').filter(e => {
                                            return e['Geschäftsfeld'].includes('gp');
                                        }).sort((a, b) => (b['Jahr'] > a['Jahr']) ? 1 : -1),
                                        fp: this.parse('RL').filter(e => {
                                            return e['Geschäftsfeld'].includes('fp') && !e['Geschäftsfeld'].includes('gp');
                                        }).sort((a, b) => (b['Jahr'] > a['Jahr']) ? 1 : -1),
                                        hp: this.parse('RL').filter(e => {
                                            return e['Geschäftsfeld'].includes('hp') && !e['Geschäftsfeld'].includes('gp') && !e['Geschäftsfeld'].includes('fp');
                                        }).sort((a, b) => (b['Jahr'] > a['Jahr']) ? 1 : -1),
                                    },
                                    projects: {
                                        gp: this.parse('RL').filter(e => {
                                            return e['Geschäftsfeld'].includes('gp');
                                        }).sort((a, b) => (b['Jahr'] > a['Jahr']) ? 1 : -1),
                                        fp: this.parse('RL').filter(e => {
                                            return e['Geschäftsfeld'].includes('fp') && !e['Geschäftsfeld'].includes('gp');
                                        }).sort((a, b) => (b['Jahr'] > a['Jahr']) ? 1 : -1),
                                        hp: this.parse('RL').filter(e => {
                                            return e['Geschäftsfeld'].includes('hp') && !e['Geschäftsfeld'].includes('gp') && !e['Geschäftsfeld'].includes('fp');
                                        }).sort((a, b) => (b['Jahr'] > a['Jahr']) ? 1 : -1),
                                    },
                                    publications: {
                                        gp: this.parse('VO').sort((a, b) => {
                                            let aa = a['Jahr'].split('/').reverse().join(),
                                                bb = b['Jahr'].split('/').reverse().join();

                                            return bb > aa ? 1 : -1;
                                        })
                                    }
                                }} section={this.state.current}
                                   hideDownloads={() => this.setState({showDownload: false})}
                                   businessArea={this.props.businessArea}
                                   service={this.props.service}
                                   activityArea={this.props.activityArea}
                                   sections={sections}
                                   search={this.state.search}
                                />
                            }
                        </div>
                    }

                </div>

                <div className={'referenceHead ' + this.state.current}>

                    <div className={'first box'}>
                        <div className={'pseudoLink'} onClick={() => this.changeSort('Jahr', this.state.sort)}>
                            Jahr
                            <div className={'arrowBox'}>
                                {this.state.sortBy === 'Jahr' ? <span className={'arrow ' + this.state.sort} /> : <span className={'arrow disabled'} />}
                            </div>
                        </div>
                    </div>

                    <div className={'info box'} />

                    <div className={'second box'}>
                        <div className={'pseudoLink'} onClick={() => this.changeSort((this.state.current === 'publications' ? 'Name' : 'Projektbezeichnung'), this.state.sort)}>
                            {this.state.current === 'publications' ?
                                'Autor / Kurzbeschreibung' :
                                'Projektbezeichnung / Kurzbeschreibung'
                            }
                            <div className={'arrowBox'}>
                                {(this.state.sortBy === 'Projektbezeichnung' || this.state.sortBy === 'Name') ? <span className={'arrow ' + this.state.sort} /> : <span className={'arrow disabled'} />}
                            </div>
                        </div>
                    </div>

                    {this.state.current === 'projects' &&
                        <div className={'fourth box'}>
                            <div className={'pseudoLink'} onClick={() => this.changeSort('Projektvolumen', this.state.sort)}>
                                Projektvolumen
                                <div className={'arrowBox'}>
                                    {(this.state.sortBy === 'Projektvolumen') ? <span className={'arrow ' + this.state.sort} /> : <span className={'arrow disabled'} />}
                                </div>
                            </div>
                        </div>
                    }
                    {(this.state.current === 'references' || this.state.current === 'publications') &&
                    <div className={'fourth box'}>
                        <div className={'pseudoLink'}>
                            Geschäftsfelder
                        </div>
                    </div>
                    }

                    <div className={'third box'}>
                        <div className={'pseudoLink'} onClick={() => this.changeSort((this.state.current !== 'publications' ? 'AG' : 'Quelle'), this.state.sort)}>
                            {this.state.current !== 'publications' ? 'Auftraggeber' : 'Quellenangabe'}
                            <div className={'arrowBox'}>
                                {(this.state.sortBy === 'AG' || this.state.sortBy === 'Quelle') ? <span className={'arrow ' + this.state.sort} /> : <span className={'arrow disabled'} />}
                            </div>
                        </div>
                    </div>

                    {this.state.current === 'publications' &&
                        <div className={'dl box'}>PDF</div>
                    }
                </div>

                <div className={'tabContent ' + (this.state.current === 'references' ? 'current' : '')}>

                    <div className="horizontalLine" />

                    <div className={'referenceListContent'}>
                        <div className={'list'}>
                            {this.state.filtered.length > 0 ? this.state.filtered.slice(0, this.state.maxEntries).map((entry, key) => {
                                let y = (year === entry['Jahr']) ? '' : entry['Jahr'];
                                year = entry['Jahr'];

                                return (
                                    <div className={'row ' + ((!!entry['Projektinfo'] && !entry['Projektinfo'].includes('#')) ? 'hover' : '')} key={key} onClick={() => {if (!!entry['Projektinfo'] && !entry['Projektinfo'].includes('#')) {this.popup(entry)}}}>
                                        <div className={'rowInner'}>
                                            <div className={'first'}>
                                                <span dangerouslySetInnerHTML={{__html: this.highlight(y)}} />
                                            </div>

                                            <div className={'info'}>
                                                {(!!entry['Projektinfo'] && !entry['Projektinfo'].includes('#')) &&
                                                <span className={'icon-info info'} />
                                                }
                                            </div>

                                            <div className={'right'}>

                                                <div className={'second'}>
                                                    <strong>
                                                        <span dangerouslySetInnerHTML={{__html: this.highlight(entry['Projektbezeichnung'])}} />
                                                    </strong>
                                                    <p>
                                                        <span dangerouslySetInnerHTML={{__html: this.highlight(entry['Kurzbeschreibung'])}} />
                                                    </p>
                                                </div>

                                                <div className={'area'}>
                                                    {this.getIcons(entry['Geschäftsfeld'])}
                                                </div>

                                                <div className={'third'}>
                                                    <strong>
                                                        <span dangerouslySetInnerHTML={{__html: this.highlight(entry['AG'])}} />
                                                    </strong>
                                                    <p>
                                                        <span dangerouslySetInnerHTML={{__html: this.highlight(entry['Ort'])}} />
                                                    </p>
                                                </div>

                                            </div>
                                        </div>
                                        <div className={'line'} />
                                    </div>
                                );
                            }) : <div className={'noEntries'}>Hier gibt es momentan keine Einträge. Wir arbeiten hart daran...</div>}

                            {(this.state.filtered.length > 0 && this.state.filtered.length > this.state.maxEntries) ?
                                <>
                                    {this.state.maxEntries === 10 ?
                                        <div className={'moreEntries'} onClick={() => this.showMore()}>mehr Einträge Anzeigen</div> :
                                        <div className={'entryOptions ' + (this.state.filtered.length <= 10 ? 'hide' : '')}>
                                            <div className={'more'} onClick={() => this.showMore()} title={'mehr Einträge Anzeigen'}>mehr</div>
                                            <div className={'reset icon-close'} onClick={() => this.resetMore()} title={'Einträge zurücksetzen'} />
                                            <div className={'less'} onClick={() => this.showLess('.referenceListContent .scrollToMe')} title={'weniger Einträge Anzeigen'}>weniger</div>
                                        </div>
                                    }
                                </> :
                                <>
                                    <div className={'entryOptions ' + (this.state.filtered.length <= 10 ? 'hide' : '')}>
                                        <div className={'reset icon-close'} onClick={() => this.resetMore()} title={'Einträge zurücksetzen'} />
                                    </div>
                                </>
                            }

                            <div className={'scrollToMe'} />
                        </div>
                    </div>
                </div>

                <div className={'tabContent ' + (this.state.current === 'projects' ? 'current' : '')}>

                    <div className="horizontalLine" />

                    <div className={'referenceProjectsContent'}>
                        <div className={'list'}>
                            {this.state.references.length > 0 ? this.state.references.slice(0, this.state.maxEntries).map((entry, key) => {
                                let y = (year === entry['Jahr']) ? '' : entry['Jahr'];
                                year = entry['Jahr'];

                                let images = !!entry['Projektfotos'] ? entry['Projektfotos'].split(',') : [undefined];

                                return (
                                    <div className={'row'} key={key} onClick={() => this.popup(entry)}>
                                        <div className={'rowInner'}>
                                            <div className={'first'}>
                                                <span dangerouslySetInnerHTML={{__html: this.highlight(y)}} />
                                            </div>

                                            <div className={'image'}>
                                                <div className={'imageBox'}>
                                                    <LazyImage dkey={'slider_img_' + key} className={'img'} alt={'slider_img_' + key} src={'image.php?file=img/referenzen/' + images[0] + '&height=100&width=200&'} width={200} height={100} forceLoad={this.state.current === 'projects'} />
                                                    <div className={'area'}>
                                                        {this.getIcons(entry['Geschäftsfeld'])}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className={'right'}>
                                                <div className={'second'}>
                                                    <strong>
                                                        <span dangerouslySetInnerHTML={{__html: this.highlight(entry['Projektbezeichnung'])}} />
                                                    </strong>
                                                    <p>
                                                        <span dangerouslySetInnerHTML={{__html: this.highlight(entry['Kurzbeschreibung'])}} />
                                                    </p>
                                                </div>

                                                <div className={'fourth'}>
                                                    <strong>
                                                        <span dangerouslySetInnerHTML={{__html: ((!!entry['Projektvolumen'] && !entry['Projektvolumen'].includes('?')) ? new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(entry['Projektvolumen']) : entry['Projektvolumen'])}} />
                                                    </strong>
                                                </div>

                                                <div className={'third'}>
                                                    <strong>
                                                        <span dangerouslySetInnerHTML={{__html: this.highlight(entry['AG'])}} />
                                                    </strong>
                                                    <p>
                                                        <span dangerouslySetInnerHTML={{__html: this.highlight(entry['Ort'])}} />
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={'line'} />
                                    </div>
                                );
                            }) : <div className={'noEntries'}>Hier gibt es momentan keine Einträge. Wir arbeiten hart daran...</div>}

                            {(this.state.references.length > 0 && this.state.references.length > this.state.maxEntries) ?
                                <>
                                    {this.state.maxEntries === 10 ?
                                        <div className={'moreEntries'} onClick={() => this.showMore()}>mehr Einträge Anzeigen</div> :
                                        <div className={'entryOptions ' + (this.state.filtered.length <= 10 ? 'hide' : '')}>
                                            <div className={'more'} onClick={() => this.showMore()} title={'mehr Einträge Anzeigen'}>mehr</div>
                                            <div className={'reset icon-close'} onClick={() => this.resetMore()} title={'Einträge zurücksetzen'} />
                                            <div className={'less'} onClick={() => this.showLess('.referenceProjectsContent .scrollToMe')} title={'weniger Einträge Anzeigen'}>weniger</div>
                                        </div>
                                    }
                                </> :
                                <>
                                    <div className={'entryOptions ' + (this.state.filtered.length <= 10 ? 'hide' : '')}>
                                        <div className={'reset icon-close'} onClick={() => this.resetMore()} title={'Einträge zurücksetzen'} />
                                    </div>
                                </>
                            }

                            <div className={'scrollToMe'} />
                        </div>
                    </div>
                </div>

                <div className={'tabContent ' + (this.state.current === 'publications' ? 'current' : '')}>

                    <div className="horizontalLine" />

                    <div className={'referenceListContent referencePublicationsContent'}>
                        <div className={'list'}>
                            {this.state.publications.length > 0 ? this.state.publications.slice(0, this.state.maxEntries).map((entry, key) => {
                                let y = (year === entry['Jahr']) ? '' : entry['Jahr'];
                                year = entry['Jahr'];

                                return (
                                    <div className={'row'} key={key}>
                                        <div className={'rowInner'}>
                                            <div className={'first'}>
                                                <span dangerouslySetInnerHTML={{__html: this.highlight(y)}} />
                                            </div>

                                            <div className={'info'}>
                                                {!!entry['Projektinfo'] &&
                                                    <span className={'icon-info info'} />
                                                }
                                            </div>

                                            <div className={'right'}>
                                                <div className={'second'}>
                                                    <strong>
                                                        <span dangerouslySetInnerHTML={{__html: this.highlight(entry['Name'])}} />
                                                    </strong>
                                                    <p>
                                                        <span dangerouslySetInnerHTML={{__html: this.highlight(entry['Kurzbeschreibung'])}} />
                                                    </p>
                                                </div>

                                                <div className={'area'}>
                                                    {this.getIcons(entry['Geschäftsfeld'])}
                                                </div>

                                                <div className={'third'}>
                                                    <p>
                                                        <em>
                                                            <span dangerouslySetInnerHTML={{__html: this.highlight(entry['Quelle'])}} />
                                                        </em>
                                                    </p>
                                                </div>

                                                <div className={'fourth download'}>
                                                    {
                                                        !!entry['Download'] ?
                                                            <a href={entry['Download']} target={'_blank'} rel={'noreferrer'} title={'PDF herunterladen'}>
                                                                <span className={'icon-download'} />
                                                            </a>
                                                            :
                                                            <span className={'icon-download'} />
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                        <div className={'line'} />
                                    </div>
                                );
                            }) : <div className={'noEntries'}>Hier gibt es momentan keine Einträge. Wir arbeiten hart daran...</div>}

                            {(this.state.publications.length > 0 && this.state.publications.length > this.state.maxEntries) ?
                                <>
                                    {this.state.maxEntries === 10 ?
                                        <div className={'moreEntries'} onClick={() => this.showMore()}>mehr Einträge Anzeigen</div> :
                                        <div className={'entryOptions ' + (this.state.filtered.length <= 10 ? 'hide' : '')}>
                                            <div className={'more'} onClick={() => this.showMore()} title={'mehr Einträge Anzeigen'}>mehr</div>
                                            <div className={'reset icon-close'} onClick={() => this.resetMore()} title={'Einträge zurücksetzen'} />
                                            <div className={'less'} onClick={() => this.showLess('.referencePublicationsContent .scrollToMe')} title={'weniger Einträge Anzeigen'}>weniger</div>
                                        </div>
                                    }
                                </> :
                                <>
                                    <div className={'entryOptions ' + (this.state.filtered.length <= 10 ? 'hide' : '')}>
                                        <div className={'reset icon-close'} onClick={() => this.resetMore()} title={'Einträge zurücksetzen'} />
                                    </div>
                                </>
                            }

                            <div className={'scrollToMe'} />
                        </div>
                    </div>
                </div>

                {!!this.state.popup &&
                    <ReferencePopup data={this.state.references} currentId={this.state.popup} popup={this.popup} />
                }

            </div>
        );
    }

    componentDidMount() {
        this.params = this.props.location.pathname.replace('/', '');
        this.mount();
        this.resetState = Object.assign({}, this.state);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.path !== this.props.path) {
            this.params = this.props.location.pathname.replace('/', '');

            this.filterEntries();
        }

        if (prevProps.businessArea !== this.props.businessArea) {
            this.setState({
                businessArea: !!this.props.businessArea ? this.props.businessArea : ''
            });

            this.filterEntries('businessArea', this.props.businessArea);
        }

        if (prevProps.service !== this.props.service) {
            this.setState({
                service: !!this.props.service ? this.props.service : ''
            });

            this.filterEntries('service', this.props.service);
        }

        if (prevProps.resetAll !== this.props.resetAll && !!this.props.resetAll) {
            this.reset();
            this.mount();
        }

        if (prevProps.activityArea !== this.props.activityArea) {
            this.setState({
                activityArea: !!this.props.activityArea ? this.props.activityArea : ''
            });

            this.filterEntries('activityArea', this.props.activityArea);
        }

        if (prevProps.search !== this.props.search) {
            this.setState({
                search: !!this.props.search ? this.props.search : ''
            });

            this.filterEntries('search', this.props.search);
        }

        if (prevProps.tab !== this.props.tab) {
            this.setState({
                current: this.props.tab
            })
        }
    }
}

export default withRouter(References);
