import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import axios from 'axios';

import Header from './components/header.class';

import Start from './components/start.class';
import Services from './components/services.class';
import Sections from './components/sections.class';
import SliderBox from './components/sliderBox.class';
import MapBox from './components/map.class';
import Contact from './components/contact.class';
import Loading from "./loading";
import About from "./components/about.class";
import References from './components/references.class';
import Imprint from './components/imprint.class';
import cookie from "cookiejs";
import CookieConsent from "./components/CookieConsent";
import {initGA} from "./components/ga-utils";
import {install} from 'ga-gtag';


const DynamicComponent = (props) => {
    let components =  {
        'Start': <Start {...props} />,
        'Services': <Services {...props} data={!!props.state.sections.rubrics.length ? props.state.sections.rubrics : null} goto={props.goto} />,
        'Sections': <Sections {...props} data={!!props.state.sections.groups ? props.state.sections.groups : null} path={props.path} openReference={props.openReference} openPublications={props.openPublications} />,
        'About': <About {...props} tab={!!props.state.ueberTab ? props.state.ueberTab : ''} />,
        'Sliderbox': <SliderBox {...props} />,
        'References' : <References {...props} data={props.state.references} sections={!!props.state.sections ? props.state.sections : null} path={props.path} service={props.service} updateParent={props.updateParent} tab={!!props.state.referenzenTab ? props.state.referenzenTab : ''} activityArea={props.state.activityArea} setActivityArea={props.setActivityArea} setTab={props.setTab} businessArea={props.businessArea} search={props.search} clickAndScroll={props.clickAndScroll} />,
        'Map': <MapBox {...props} data={!!props.data ? props.data.filter(e => e['Typ'] === 'location') : []} />,
        'Contact': <Contact {...props} data={props.state.contact.filter(e => e['Typ'] === 'kontaktformular')[0]} />
    };

    if (!!components[props.view]) {
        return components[props.view];
    } else {
        return null;
    }

};


class AppRouter extends Component {

    constructor(props) {
        super(props);

        let businessArea = '';
        if (props.location.pathname === '/felsprojekt') {
            businessArea = 'fp';
        } else if (props.location.pathname === '/hydroprojekt') {
            businessArea = 'hp';
        } else {
            businessArea = 'gp';
        }

        this.state = {
            loading: true,
            imprint: false,
            resetAll: false,
            search: '',
            businessArea: businessArea,
            service: '',
            activityArea: '',
            referenzenTab: 'references',
        };

        this.refScroll = this.refScroll.bind(this);
        this.updateParent = this.updateParent.bind(this);
        this.openReference = this.openReference.bind(this);
        this.openPublications = this.openPublications.bind(this);
        this.openTab = this.openTab.bind(this);
        this.showImprint = this.showImprint.bind(this);
        this.hideImprint = this.hideImprint.bind(this);
        this.setActivityArea = this.setActivityArea.bind(this);
        this.goTop = this.goTop.bind(this);
        this.setTab = this.setTab.bind(this);

        this.p = businessArea;
    }

    refScroll(target) {
        this.clickAndScroll('#referenzen');
        this.setState({
            businessArea: this.p,
            service: target,
            activityArea: '',
            referenzenTab: 'references',
            search: ''
        });

    }

    clickAndScroll(to, bottom = null) {
        let container = document.querySelector(to);

        if (!!container) {
            let position = container.offsetTop;

            let topBar = document.querySelector('header');
            let topBarHeight = topBar.getBoundingClientRect();

            let btm = bottom ? (position + container.getBoundingClientRect().height) - window.innerHeight : position - topBarHeight.height;

            if (!!to) {
                window.scroll({
                    top: btm,
                    behavior: 'smooth'
                });
            }
        }

    }

    goTop(to) {

        this.clickAndScroll(to);

        this.setState({
            resetAll: true
        }, () => {
            this.setState({
                resetAll: false
            });
        })

    }

    updateParent(key, value) {
        this.setState({
            [key]: value
        });
    }

    openTab(href, tab) {
        this.setState({
            [href]: tab
        });
    }

    openReference(businessArea, activityArea) {
        this.setState({
            service: '',
            businessArea: businessArea,
            activityArea: activityArea,
            referenzenTab: 'references',
            search:''
        });

        this.clickAndScroll('#referenzen');
    }

    openPublications(businessArea, activityArea) {
        this.setState({
            businessArea: businessArea,
            activityArea: '',
            service: '',
            referenzenTab: 'publications',
            search: ''
        });

        this.clickAndScroll('#referenzen');
    }

    setActivityArea(activityArea) {
        this.setState({
            activityArea: activityArea
        });
    }

    showImprint() {
        if (!this.state.imprint) {
            this.setState({
                imprint: true
            });
        } else {
            this.clickAndScroll('#impressum');
        }
    }

    hideImprint() {
        this.setState({
            imprint: false
        });
    }

    setTab(tab) {
        if (tab === 'publications') {
            this.setState({
                referenzenTab: tab,
                service: '',
                activityArea: ''
            });
        } else {
            this.setState({
                referenzenTab: tab
            });
        }
    }

    hideCookieConsent() {
        this.setState({
            hide: true
        });
    }

    showCookieConsent() {
        this.setState({
            hide: false
        });
    }

    abortCookies() {
        cookie.set('ga-disable-UA-165941736-1');

        cookie.remove('_ga', { domain: '.' + document.domain });
        // _gid is used to distinguish users.
        cookie.remove('_gid', { domain: '.' + document.domain });
        // _gat is used to throttle request rate.
        cookie.remove('_gat', { domain: '.' + document.domain });

        window.location.reload();
    }

    render() {
        let className = '';
        let path = this.props.location.pathname.replace('/', '');
        let json;

        if (path === '') {
            className = 'geoprojekt';
            json = 'geoprojekt';

            document.getElementById('themeColor').setAttribute('content', '#5a8cbe');
        } else if (path === 'geoprojekt' || path === 'felsprojekt' || path === 'hydroprojekt') {
            className = path;
            json = path;

            if (path === 'felsprojekt') {
                document.getElementById('themeColor').setAttribute('content', '#bea06e');
            } else if (path === 'hydroprojekt') {
                document.getElementById('themeColor').setAttribute('content', '#46a0aa');
            } else {
                document.getElementById('themeColor').setAttribute('content', '#5a8cbe');
            }
        } else {
            className = 'geoprojekt';
            json = 'geoprojekt';

            document.getElementById('themeColor').setAttribute('content', '#5a8cbe');
        }

        if (this.state.loading) {
            return <Loading css={className} />
        } else {
            return (
                <div className={'pageContainer ' + className}>

                    <Header data={this.state['data'][json]['menu']} goto={this.goTop} openTab={this.openTab} showImprint={() => this.showImprint()} />

                    <div id={'content'}>

                        {
                            this.state['data'][json]['structure'] &&
                            this.state['data'][json]['structure'].map(elm => {
                                if (elm['View'] && elm['View'] !== 'Imprint') {
                                    return <DynamicComponent
                                        id={elm['ID']}
                                        view={elm['View']}
                                        data={this.state['data'][json][elm['Lasche']]}
                                        key={elm['ID']}
                                        path={path}
                                        goto={this.refScroll}
                                        service={this.state.service}
                                        updateParent={this.updateParent}
                                        openReference={this.openReference}
                                        openPublications={this.openPublications}
                                        state={this.state}
                                        setActivityArea={this.setActivityArea}
                                        resetAll={this.state.resetAll}
                                        setTab={this.setTab}
                                        businessArea={this.state.businessArea}
                                        search={this.state.search}
                                        clickAndScroll={this.clickAndScroll}
                                        loadGoogleScripts={() => this.showCookieConsent()}
                                    />;
                                } else {
                                    return null;
                                }
                            })
                        }

                        <footer id={'footer'}>
                            <div className={'shape_1'} />
                            <div className={'shape_2'} />

                            <div className={'footerInner'}>
                                <div className={'footerContainer'}>
                                    <div className={'name'}>
                                        witt & partner geoprojekt {new Date().getFullYear()}
                                    </div>

                                    <div className={'links'}>
                                        <span onClick={() => this.showImprint()}>Impressum</span>
                                        <span onClick={() => this.showImprint()}>Datenschutz</span>
                                    </div>
                                </div>

                            </div>

                        </footer>

                        <Imprint hide={() => this.hideImprint()} show={this.state.imprint} data={this.state.imprintJson} resetAll={this.state.resetAll} showCookieConsent={() => this.showCookieConsent()} />

                        {(!cookie.get('wup_cookie') || this.state.hide === false) &&
                            <CookieConsent hideCookieConsent={() => this.hideCookieConsent()} abortCookies={() => this.abortCookies()} />
                        }
                    </div>
                </div>
            );
        }
    }

    componentDidMount() {

        axios.get((process.env['NODE_ENV'] === 'development' ? process.env['REACT_APP_URI'] : window.location.protocol + '//' + window.location.hostname) + '/json.php', {
            'headers': {
                'Accept': 'application/json',
                'X-Requested-With': 'XMLHttpRequest'
            }
        }).then(result => {
            let data = result.data;

            let structure = {
                gp: data['Struktur'].filter(s => {
                    return s['Geschäftsfeld'] === 'gp'
                }),
                fp: data['Struktur'].filter(s => {
                    return s['Geschäftsfeld'] === 'fp'
                }),
                hp: data['Struktur'].filter(s => {
                    return s['Geschäftsfeld'] === 'hp'
                })
            };

            structure = {
                gp: structure.gp.length ? structure.gp : data['Struktur'].filter(s => {
                    return !s['Geschäftsfeld']
                }),
                fp: structure.fp.length ? structure.fp : data['Struktur'].filter(s => {
                    return !s['Geschäftsfeld']
                }),
                hp: structure.hp.length ? structure.hp : data['Struktur'].filter(s => {
                    return !s['Geschäftsfeld']
                }),
            };

            let menu = {
                gp: data['Menue'].filter(s => {
                    return s['Geschäftsfeld'] === 'gp'
                }),
                fp: data['Menue'].filter(s => {
                    return s['Geschäftsfeld'] === 'fp'
                }),
                hp: data['Menue'].filter(s => {
                    return s['Geschäftsfeld'] === 'hp'
                })
            };
            menu = {
                gp: menu.gp.length ? menu.gp : data['Menue'].filter(s => {
                    return !s['Geschäftsfeld']
                }),
                fp: menu.fp.length ? menu.fp : data['Menue'].filter(s => {
                    return !s['Geschäftsfeld']
                }),
                hp: menu.hp.length ? menu.hp : data['Menue'].filter(s => {
                    return !s['Geschäftsfeld']
                }),
            };

            this.setState({
                loading: false,
                'data': {
                    'geoprojekt': {
                        'structure': structure.gp,
                        'menu': menu.gp,
                        'Start': data['Start'].filter(s => {
                            return s['Geschäftsfeld'] === 'gp'
                        }),
                        'News': [
                            ...data['News'].filter(s => {
                                return s['Geschäftsfeld'] === 'gp'
                            }), ...data['News'].filter(s => {
                                return !s['Geschäftsfeld']
                            })
                        ],
                        'Ueber': [
                            ...data['Ueber'].filter(s => {
                                return s['Geschäftsfeld'] === 'gp'
                            }),
                            ...data['Ueber'].filter(s => {
                                return !s['Geschäftsfeld']
                            })
                        ],
                        [structure.gp.filter(s => s['ID'] === 'kontakt')[0]['Lasche']]: [
                            ...data[structure.gp.filter(s => s['ID'] === 'kontakt')[0]['Lasche']]
                        ]
                    },
                    'felsprojekt': {
                        'structure': structure.fp,
                        'menu': menu.fp,
                        'Start': data['Start'].filter(s => {
                            return s['Geschäftsfeld'] === 'fp'
                        }),
                        'News': [
                            ...data['News'].filter(s => {
                                return s['Geschäftsfeld'] === 'fp'
                            }),
                            ...data['News'].filter(s => {
                                return !s['Geschäftsfeld']
                            })
                        ],
                        'Ueber_Fels': [
                            ...data['Ueber'].filter(s => {
                                return s['Geschäftsfeld'] === 'fp'
                            }),
                            ...data['Ueber_Fels'].filter(s => {
                                return !s['Geschäftsfeld']
                            })
                        ],
                        [structure.fp.filter(s => s['ID'] === 'kontakt')[0]['Lasche']]: [
                            ...data[structure.fp.filter(s => s['ID'] === 'kontakt')[0]['Lasche']]
                        ]
                    },
                    'hydroprojekt': {
                        'structure': structure.hp,
                        'menu': menu.hp,
                        'Start': data['Start'].filter(s => {
                            return s['Geschäftsfeld'] === 'hp'
                        }),
                        'News': [
                            ...data['News'].filter(s => {
                                return s['Geschäftsfeld'] === 'hp'
                            }),
                            ...data['News'].filter(s => {
                                return !s['Geschäftsfeld']
                            })
                        ],
                        'Ueber': [
                            ...data['Ueber'].filter(s => {
                                return s['Geschäftsfeld'] === 'hp'
                            }),
                            ...data['Ueber'].filter(s => {
                                return !s['Geschäftsfeld']
                            })
                        ],
                        [structure.hp.filter(s => s['ID'] === 'kontakt')[0]['Lasche']]: [
                            ...data[structure.hp.filter(s => s['ID'] === 'kontakt')[0]['Lasche']]
                        ]
                    }
                },
                'sections': {
                    rubrics: data['Taetigkeitsfelder'].filter(s => {
                        return s['Typ'] === 'Rubrik'
                    }),
                    groups: {
                        gp: data['Taetigkeitsfelder'].filter(s => {
                            return s['Geschäftsfeld'] === 'gp' && s['Typ'] !== 'Rubrik';
                        }),
                        fp: data['Taetigkeitsfelder'].filter(s => {
                            return s['Geschäftsfeld'] === 'fp' && s['Typ'] !== 'Rubrik';
                        }),
                        hp: data['Taetigkeitsfelder'].filter(s => {
                            return s['Geschäftsfeld'] === 'hp' && s['Typ'] !== 'Rubrik';
                        }),
                    }
                },
                'references': {
                    RL: data.RL,
                    // RP: data.RP,
                    VO: data.VOE
                },
                'contact': data['Kontakt'],
                'imprintJson': data['Impressum'][0]
            }, () => {
                this.updateMetaTags();
                this.updateMetaDescription();
                this.scrollToPositionAfterLoad();
            });

        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.location !== this.props.location) {
            if (this.props.location.pathname === '/felsprojekt') {
                this.setState({
                    businessArea: 'fp',
                    service: '',
                    activityArea: ''
                });
                this.p = 'fp';
            } else if (this.props.location.pathname === '/hydroprojekt') {
                this.setState({
                    businessArea: 'hp',
                    service: '',
                    activityArea: ''
                });
                this.p = 'hp';
            } else {
                this.setState({
                    businessArea: 'gp',
                    service: '',
                    activityArea: ''
                });
                this.p = 'gp';
            }

            this.updateMetaTags();
            this.updateMetaDescription();
        }

        if (this.state.hide === true && cookie.get('wup_cookie') === '1') {
            this.loadGoogleScripts();
        }
    }

    loadGoogleScripts() {
        const gaProperty = 'UA-165941736-1';

        initGA(gaProperty);
        install(gaProperty, { 'send_page_view': false });
    }

    updateMetaTags() {
        let keywords = '';
        const metaKeywords = document.getElementById('keywords');

        if (this.props.location.pathname === '/felsprojekt') {
            keywords = 'Geotechnik, Geoprojekt, Beratung, Planung, Prüfung, Erdbau, Grundbau, Verkehrswegebau, Infrastruktur, Böschungen, Baugruben, Gründungen, Deponien, Forschung, Fachbauleitung, Weimar, Hannover, Prüfsachverständige';
        } else if (this.props.location.pathname === '/hydroprojekt') {
            keywords = 'Geotechnik, hydroprojekt, Beratung, Planung, Prüfung, Wasserbau, Hochwasserschutz, Deiche, Wasserstraßen, Uferbefestigungen, Grundwasser, Grundwasserabsenkung, Strömungssimulation, Verkehrswegebau, Böschungen, Baugruben, Gründungen, Forschung, Fachbauleitung, Weimar, Hannover, Prüfsachverständige';
        } else {
            keywords = 'Geotechnik, felsprojekt, Beratung, Planung, Prüfung, Felsbau, Tunnelbau, Festgestein, Felssicherung, Steinschlagschutzbauten, Steinschlagsimulation, Lagerstätten, Verkehrswegebau, Böschungen, Baugruben, Gründungen, Tunnel, Kavernen, Stollen, Forschung, Fachbauleitung, Weimar, Hannover, Bad Tölz, Prüfsachverständige';
        }

        if (!metaKeywords) {
            let meta = document.createElement('meta');
            meta.name = 'keywords';
            meta.content = keywords
            meta.id = 'keywords';

            document.querySelector('head').appendChild(meta);

            let metadesc = document.createElement('meta');
            metadesc.name = 'description';
            metadesc.content = keywords
            metadesc.id = 'description';

            document.querySelector('head').appendChild(metadesc);
        } else {
            metaKeywords.setAttribute('content', keywords);
        }
    }

    updateMetaDescription() {
        const metaDescription = document.getElementById('description');

        let path = this.props.location.pathname.replace('/', '');
        let json;

        if (path === '') {
            json = 'geoprojekt';
        } else if (path === 'geoprojekt' || path === 'felsprojekt' || path === 'hydroprojekt') {
            json = path;
        } else {
            json = 'geoprojekt';
        }

        const description = !!this.state.data ? this.state.data[json]['Start'].filter(d => d['typ'] === 'willkommen_text')[0]['Inhalt'].replaceAll('*', '') : '';

        if (!metaDescription) {
            let metadesc = document.createElement('meta');
            metadesc.name = 'description';
            metadesc.content = description;
            metadesc.id = 'description';

            document.querySelector('head').appendChild(metadesc);
        } else {
            metaDescription.setAttribute('content', description);
        }
    }

    scrollToPositionAfterLoad() {
        setTimeout(() => {
            const loc = window.location;
            if (loc.hash) {
                this.clickAndScroll(loc.hash)
            }
        }, 100)
    }
}

export default withRouter(AppRouter);
