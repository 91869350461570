import React from 'react';
import {Page, Text, View, Document, Font, Image} from '@react-pdf/renderer';

import logoGP from '../assets/img/logos/logo_geo_dunkel.png';
import logoFP from '../assets/img/logos/logo_fels_dunkel.png';
import logoHP from '../assets/img/logos/logo_hydro_dunkel.png';

import regular from '../assets/font/Merriweather-Regular.ttf';
import bold from '../assets/font/Merriweather-Bold.ttf';
import italic from '../assets/font/Merriweather-Italic.ttf';

import gp from '../assets/img/area/gp.png';
import fp from '../assets/img/area/fp.png';
import hp from '../assets/img/area/hp.png';

Font.register({
    family: 'Open Sans',
    fonts: [
        {src: regular},
        {src: bold, fontWeight: 700},
        {src: italic, fontStyle: 'italic'}
    ]
});

function getIcons(businessArea, list) {
    if (!list) return <span />;

    let l = list.split(',').map(i => i.trim());
    let arr = [];

    let img = {
        gp: gp,
        fp: fp,
        hp: hp
    };

    if (!!businessArea) {
        arr.push(businessArea);
        let filter = l.filter(i => i !== businessArea);

        arr = [...arr, ...filter];
    } else {
        arr = l;
    }

    return arr.map(s => {
        if (!!s) {
            return <Image src={img[s]} key={s} style={{width:15, height:15, margin:'0 1px'}} />
        } else {
            return <Text key={s} />;
        }

    });
}

function RenderEntries(props) {
    let entries = props.data;
    let year;

    let max = 12;
    if (props.section === 'projects') {
        max = 10
    } else if (props.section === 'publications') {
        max = 11;
    }


    let row = [];

    for (let i = (props.page * max); i < (max * (props.page+1)); i++) {
        let entry = entries[i];

        if (!!entry && Object.keys(entry).length) {
            let y = (year === entry['Jahr']) ? '' : entry['Jahr'];
            let border = (i < (max * (props.page+1)-1) && i < entries.length-1) ? 1 : 0;

            row.push(
                <View style={{borderBottomWidth:border, borderStyle:'solid', borderBottomColor:'#ccc', fontSize:8, display:'flex', flexDirection:'row', minHeight:50, padding:'4 2 2', boxSizing:'border-box'}} key={'entry_' + i}>
                    <View style={{flex:1, textAlign:'center', maxWidth:45}}>
                        <Text>
                            {y}
                        </Text>
                    </View>

                    {(props.section === 'projects') && (
                        !!entry['Projektfotos'] ?
                            <View style={{flex:1, display:'flex', flexDirection:'column', justifyContent:'center', maxWidth:80, marginRight:10}}>
                                <Image style={{height:50}} src={'assets/img/referenzen/' + entry['Projektfotos'].split(',')[0]}/>
                            </View>
                        :
                            <View style={{flex:1, display:'flex', flexDirection:'column', justifyContent:'center', maxWidth:80, marginRight:10}} />
                        )
                    }

                    <View style={{flex:(props.section === 'projects' ? 4 : 5), display:'flex', flexDirection:'column', paddingRight:20}}>

                        {!!entry['Name'] &&
                            <Text style={{fontWeight:'bold'}}>
                                {entry['Name']}
                            </Text>
                        }

                        {!!entry['Projektbezeichnung'] &&
                            <Text style={{fontWeight:'bold'}}>
                                {entry['Projektbezeichnung']}
                            </Text>
                        }

                        {!!entry['Kurzbeschreibung'] &&
                            <Text>
                                {entry['Kurzbeschreibung']}
                            </Text>
                        }

                    </View>

                    <View style={{width:51, display:'flex', flexDirection:'row', justifyContent:'center', paddingRight:20}}>
                        {(!!entry['Geschäftsfeld'] && entry['Geschäftsfeld'] !== '') &&
                            getIcons(props.businessArea, entry['Geschäftsfeld'])
                        }
                    </View>

                    <View style={{flex:3, display:'flex', flexDirection:'column', wordBreak:'break-all', overflow:'hidden'}}>
                        {!!entry['AG'] &&
                        <Text style={{fontWeight:'bold'}}>
                            {entry['AG']}
                        </Text>
                        }

                        {!!entry['Ort'] &&
                        <Text>
                            {entry['Ort']}
                        </Text>
                        }

                        {!!entry['Quelle'] &&
                        <Text style={{overflow:'hidden', fontStyle:'italic', wordBreak:'break-all'}}>
                            {entry['Quelle'].replace('\n', '')}
                        </Text>
                        }

                        {!!entry['Projektvolumen'] &&
                        <View>
                            <Text>
                                {entry['Tätigkeitsfeld'].includes('fe') ? 'Fördersumme':'Projektvolumen'}: {((!!entry['Projektvolumen'] && !entry['Projektvolumen'].includes('?')) ? new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(entry['Projektvolumen']) : entry['Projektvolumen'])}
                            </Text>
                        </View>
                        }
                    </View>

                </View>
            )
        }
    }

    return row;
}

function RenderFooter() {
    return (
        <View style={{
            height:60,
            position: 'absolute',
            bottom: 10,
            left: 20,
            right: 20,
            display: 'flex',
            flexDirection: 'column'
        }}>
            <View style={{
                borderTopWidth: 1,
                borderTopStyle: 'solid',
                borderTopColor: '#000',
                paddingTop: 10,
                display: 'flex',
                flexDirection: 'row',
                fontSize: 4.5
            }}>
                <View style={{flex: 1}}>
                    <Text style={{fontWeight: 600}}>w&amp;p geoprojekte GmbH</Text>
                    <Text>Amtsgericht Jena HRB 501103</Text>
                    <Text>URL: www.wittundpartner.com</Text>
                    <Text style={{fontWeight: 600}}>Geschäftsführer</Text>
                    <Text>Dipl.-Ing. Christian Ernst</Text>
                    <Text>Dipl.-Ing. Torsten Söhle</Text>
                    <Text>Dipl.-Geol. Gerald Wiesner</Text>
                </View>

                <View style={{flex: 1}}>
                    <Text style={{fontWeight: 600}}>Firmensitz Weimar</Text>
                    <Text>Heinrich-Heine-Str. 8, 99423 Weimar</Text>
                    <Text>Tel / Fax: (03643) 77 399 -27 / -28</Text>
                    <Text>Mail: weimar@wittundpartner.de</Text>
                    <Text>IBAN DE75 12030000 1018900751</Text>
                    <Text>BIC BYLADEM 1001</Text>
                </View>

                <View style={{flex: 1}}>
                    <Text style={{fontWeight: 600}}>Niederlassung Hannover</Text>
                    <Text>Am Jungfernplan 9, 30171 Hannover</Text>
                    <Text>Tel / Fax: (0511) 848 774 -61 / -65</Text>
                    <Text>Mail: hannover@wittundpartner.de</Text>
                    <Text>Postbank Hannover</Text>
                    <Text>IBAN DE89 2501 0030 0111 6743 04</Text>
                    <Text>BIC PBNKDEFF</Text>
                </View>

                <View style={{flex: 1}}>
                    <Text style={{fontWeight: 600}}>Niederlassung Nordrhein-Westfalen</Text>
                    <Text>Büro Düsseldorf</Text>
                    <Text>Areal Böhler, Gebäude 30</Text>
                    <Text>40549 Düsseldorf</Text>
                    <Text>Tel / Fax: (0211) 528 095 83 / 528 291 99</Text>
                    <Text>Mail: düsseldorf@wittundpartner.de</Text>
                </View>

                <View style={{flex: 1}}>
                    <Text style={{fontWeight: 600}}>Univ. Prof. Dr.-Ing. Karl Josef Witt</Text>
                    <Text>witt & partner geoprojekt GmbH</Text>
                    <Text>Heinrich-Heine-Straße 8</Text>
                    <Text>99423 Weimar</Text>
                    <Text>Tel / Fax: (03643) 77 399 -27 / -28</Text>
                    <Text>URL: www.wittundpartner.com</Text>
                    <Text>Mail: witt@wittundpartner.de</Text>
                </View>

                <View style={{flex: 1}}>
                    <Text style={{fontWeight: 600}}>Niederlassung Bayern</Text>
                    <Text>w&p felsprojekt GmbH</Text>
                    <Text>Arzbacher Straße 3</Text>
                    <Text>83646 Bad Tölz</Text>
                    <Text>Tel / Fax: (08041) 79 267 –80 / -89</Text>
                    <Text>URL: www.felsprojekt.de</Text>
                    <Text>Mail: info@felsprojekt.de</Text>
                </View>
            </View>
        </View>
    );
}

function RenderPages(props) {

    let max = 12;
    if (props.section === 'projects') {
        max = 10
    } else if (props.section === 'publications') {
        max = 11;
    }

    let data = {};
    if (!!props.data.filtered) {
        if (props.businessArea === '') {
            data[''] = [...new Set([...props.data.filtered])];
        } else {
            data[''] = props.data.filtered;
        }
    } else {
        data = props.data.full;
    }

    let page = [];

    let service = {
        be:'Beratung',
        pl:'Planung',
        pr:'Prüfung'
    };


    if (Object.keys(data).length) {
        Object.keys(data).forEach(j => {
            let g = data[j];
            let pages = Math.ceil(g.length / max);

            let date = new Date();

            for(let i = 0; i < pages; i++) {
                let logo;
                let color;
                let section;

                if (props.businessArea === 'fp') {
                    logo = logoFP;
                    color = '#bea06e';
                    section = 'Felsprojekt';
                } else if (props.businessArea === 'hp') {
                    logo = logoHP;
                    color = '#46a0aa';
                    section = 'Hydroprojekt';
                } else if (props.businessArea === 'gp') {
                    logo = logoGP;
                    color = '#5A8CBE';
                    section = 'Geoprojekt';
                } else {
                    logo = logoGP;
                    color = '#5A8CBE';
                    section = 'Alle Geschäftsfelder';
                }

                let aa;

                if (!!props.activityArea) {
                    aa = props.sections.filter(e => {
                        return e.value === props.activityArea
                    })[0]['text'];
                }


                let str = section + (!!props.service ? ' > ' + service[props.service] : '') + ((!!props.activityArea && !!aa) ? ' > ' + aa : '');

                let sectionHead;
                if (props.section === 'publications') {
                    sectionHead = <View style={{width:'67.5%', paddingLeft:45, boxSizing:'border-box'}}>
                        <Text style={{fontSize:12, color:color}}>Veröffentlichungen</Text>
                    </View>
                } else if (props.section === 'projects') {
                    sectionHead = <View style={{width:'67.5%', paddingLeft:45, boxSizing:'border-box'}}>
                        <Text style={{fontSize:12, color:color}}>Referenzprojekte {section}</Text>
                        <Text style={{fontSize:10, color:color}}>({(!!props.data.filtered) ? str : 'Gesamt'})</Text>
                    </View>
                } else {
                    sectionHead = <View style={{width:'67.5%', paddingLeft:45, boxSizing:'border-box'}}>
                        <Text style={{fontSize:12, color:color}}>Referenzliste {section}</Text>
                        <Text style={{fontSize:10, color:color}}>({(!!props.data.filtered) ? str : 'Gesamt'})</Text>
                    </View>
                }

                page.push(
                    <Page wrap={false} size="A4" style={{fontFamily:'Open Sans', flexDirection:'column', backgroundColor:'#fff', paddingBottom:100, position:'relative'}} key={'page_' + j + '_' + i}>
                        <View>
                            <Text style={{textAlign: 'center', marginTop: 20}}>
                                <Image src={logo} style={{width: 150, height: 40, margin: 'auto'}}/>
                            </Text>
                        </View>

                        {i===0 &&
                        <View style={{margin:'20 20 0', display:'flex', flexDirection:'row'}}>
                            {sectionHead}

                            <View style={{width:'32.5%', paddingLeft:12}}>
                                <Text style={{fontSize:10}}>Datum: {date.getDate() + '.' + (date.getMonth()+1) + '.' + date.getFullYear()}</Text>
                                <Text style={{fontSize:10}}>{g.length} Einträge</Text>
                                {!!props.search && <Text style={{fontSize:10}}>Suchbegriff: {props.search}</Text>}
                            </View>
                        </View>
                        }

                        {i!==0 &&
                            <View style={{margin:5}} />
                        }

                        <View style={{margin:'20 20 0', display:'flex', flexDirection:'column'}}>
                            <RenderEntries data={g} page={i} section={props.section} businessArea={props.businessArea} />
                        </View>

                        <RenderFooter />
                    </Page>
                );
            }

        });
    }

    return page;
}

export function PdfDocument(props) {
    return (
        <Document>
            <RenderPages data={props.data} section={props.section} businessArea={props.businessArea} activityArea={props.activityArea} service={props.service} sections={props.sections} search={props.search} />
        </Document>
    )
}
