import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';

import '../assets/scss/contact.scss';


class Contact extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            sent: false,
            error: false,
            form: {
                name: '',
                company: '',
                contact: '',
                text: '',
                lolField: '',
                terms: false
            }
        };

        this.change = this.change.bind(this);
        this.send = this.send.bind(this);
    }

    change(e) {
        let form = Object.assign({}, this.state.form, {[e.target.name]: e.target.value});

        this.setState({
            form: form
        });
    }

    changeTerms() {
        let form = Object.assign({}, this.state.form, {'terms': !this.state.form.terms});

        this.setState({
            form: form
        });
    }

    send(e) {
        e.preventDefault();

        let state = this.state.form;

        this.setState({
            loading:true,
            error: false
        });

        fetch('/contact.php',{
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                // 'Content-Type': 'application/json;charset=utf-8',
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            },
            body: Object.keys(state).map(function(key) {
                return key + '=' + state[key];
            }).join('&')
        })
        .then(response => {
            if (response.ok) {
                this.setState({
                    loading:false,
                    sent: true,
                    error: false
                });
            } else {
                this.setState({
                    loading:false,
                    sent: false,
                    error: true
                });
            }
        })
        .catch(error => {
            this.setState({
                loading:false,
                sent: false,
                error: true
            });
        });
    }

    render() {

        return (
            <div id={this.props.id} className={'contact'}>

                <div className="horizontalLine" />

                <div className={'contactBox'}>
                    <div className={'box'}>

                        <h3>Nehmen Sie kontakt auf</h3>

                        <div className={''}>
                            Für allgemeine Anfragen
                        </div>
                        <a href={'mailto:' + this.props.data['Mail']}>
                            {this.props.data['Mail']}
                        </a>

                        <hr />

                        <div className={''}>
                            BÜRO WEIMAR
                        </div>
                        <a href={'tel:' + this.props.data['Telefon']}>
                            {this.props.data['TelefonWeimar']}
                        </a>

                        <a href={'mailto:' + this.props.data['KontaktWeimar']}>
                            {this.props.data['KontaktWeimar']}
                        </a>

                        <hr />

                        <div className={''}>
                            BÜRO HANNOVER
                        </div>
                        <a href={'tel:' + this.props.data['TelefonHannover']}>
                            {this.props.data['TelefonHannover']}
                        </a>

                        <a href={'mailto:' + this.props.data['KontaktHannover']}>
                            {this.props.data['KontaktHannover']}
                        </a>


                    </div>

                    <form onSubmit={(e) => this.send(e)} method={'post'} className={'box contactForm'}>

                        <h3>Kontaktformular</h3>

                        <input type={'text'} defaultValue={this.state.form.name} name={'name'} placeholder={'Ihr Name'} required={true} onChange={(e) => this.change(e)} />

                        <input type={'text'} defaultValue={this.state.form.company} name={'company'} placeholder={'Firma oder Behörde'} required={true} onChange={(e) => this.change(e)} />

                        <input type={'text'} defaultValue={this.state.form.contact} name={'contact'} placeholder={'Telefon oder E-Mail-Adresse'} required={true} onChange={(e) => this.change(e)} />

                        <textarea name={'text'} defaultValue={this.state.form.text} placeholder={'Ihre Anfrage'} required={true} onChange={(e) => this.change(e)} />

                        <input type="text" defaultValue={this.state.form.lolField} name="lolField" className="secCheck" title="Feld" onChange={(e) => this.change(e)} />

                        <div className={'checkbox'}>
                            {this.state.form.terms}
                            <input type={'checkbox'} id={'terms'} name={'terms'} required={true} onChange={(e) => this.changeTerms()} />
                            <label htmlFor={'terms'}>Datenschutzerklärung gelesen</label>
                        </div>

                        <div className={'error ' + (this.state.error ? 'visible' : '')}>
                            Ein Fehler ist aufgetreten<br />
                            Bitte Versuchen Sie es später erneut.
                        </div>

                        {!this.state.sent ?
                            <button type={'submit'} disabled={!this.state.form.terms}>
                                {this.state.loading ?
                                    <div className={'loader'}/> :
                                    'Nachricht senden'
                                }
                            </button>
                            :
                            <div className={'ok'}>
                                Vielen Dank für die Kontaktaufnahme. <br/>
                                Wir melden uns in Kürze bei Ihnen.
                            </div>
                        }
                    </form>
                </div>

            </div>
        );
    }
}

export default withRouter(Contact);
