import marked from 'marked';

let renderer = new marked.Renderer();

renderer.heading = function (text, level) {
    return '<h' + level + '>' + text + '</h' + level + '>';
};
renderer.link = function(href, title, text) {
    var link = marked.Renderer.prototype.link.apply(this, arguments);
    return link.replace("<a","<a class='external' target='_blank' rel='noreferrer'");
};

marked.setOptions({
    renderer: renderer,
    gfm: false,
    smartypants: false,
    xhtml: false,
    mangle: false,
    breaks:true
});

export default marked;
