export function go(to, id, timer, subTimer, self) {
    clearTimeout(timer);
    clearTimeout(subTimer);

    let slidesContainer = document.querySelector('#' + id + ' .slides');
    let allSlides = document.querySelectorAll('#' + id + ' .slide');
    let currentSlide = document.querySelector('#' + id + ' .slide.current');
    let index, nextCurrentSlide;

    if (to === 'next') {
        if (!!currentSlide.nextSibling) {
            nextCurrentSlide = currentSlide.nextSibling;
        } else {
            nextCurrentSlide = slidesContainer.firstChild;
        }
    } else {
        if (!!currentSlide.previousSibling) {
            nextCurrentSlide = currentSlide.previousSibling;
        } else {
            nextCurrentSlide = slidesContainer.lastChild;
        }
    }

    index = Array.prototype.indexOf.call(allSlides, nextCurrentSlide);

    if (nextCurrentSlide !== currentSlide) {
        anim(nextCurrentSlide, to === 'next', index, id, timer, subTimer, self);
    }
}


export function goTo(to, id, timer, subTimer, self) {
    clearTimeout(timer);
    clearTimeout(subTimer);

    let allSlides = document.querySelectorAll('#' + id + ' .slide');
    let currentSlide = document.querySelector('#' + id + ' .slide.current');

    let nextCurrentSlide = allSlides[to];

    if (nextCurrentSlide !== currentSlide) {
        anim(nextCurrentSlide, to > self.state.current, to, id, timer, subTimer, self);
    }

}


export function anim(nextCurrentSlide, to, key, id, timer, subTimer, self) {
    let slidesContainer = document.querySelector('#' + id + ' .slides');
    let allSlidesContainer = document.querySelectorAll('#' + id + ' .slides .slide');
    let currentSlide = document.querySelector('#' + id + ' .slide.current');
    let prev, next;

    if (!!document.querySelector('#' + id + ' .slide.prev')) {
        document.querySelector('#' + id + ' .slide.prev').classList.remove('prev');
    }

    if (!!document.querySelector('#' + id + ' .slide.next')) {
        document.querySelector('#' + id + ' .slide.next').classList.remove('next');
    }

    if (!!nextCurrentSlide && !!nextCurrentSlide.nextSibling) {
        next = nextCurrentSlide.nextSibling;
    } else {
        next = slidesContainer.firstChild;
    }

    if (!!nextCurrentSlide.previousSibling) {
        prev = nextCurrentSlide.previousSibling;
    } else {
        prev = slidesContainer.lastChild;
    }

    if (to) {
        /* remove anim and scroll box to left = 0 */
        slidesContainer.style.transition = 'none';
        slidesContainer.style.left = 0;

        /* put current slide on position 0 so it is the first visible slide */
        currentSlide.classList.add('prev');

        /* put next slide on position 1 so it is the next visible slide */
        nextCurrentSlide.classList.add('current');

        setTimeout(() => {
            slidesContainer.style.transition = '';
            slidesContainer.style.left = '-100%';
        },1);
    } else {
        next.classList.add('next');
        nextCurrentSlide.classList.add('current');

        slidesContainer.style.left = 0;
    }

    currentSlide.classList.remove('current');

    timer = setTimeout(() => {
        /* remove animation */
        slidesContainer.style.transition = 'none';

        currentSlide.classList.remove('prev');

        prev.classList.add('prev');

        if (allSlidesContainer.length > 2) {
            next.classList.add('next');
        }

        slidesContainer.style.left = '-100%';

        subTimer = setTimeout(() => {
            slidesContainer.style.transition = '';

            self.setState({
                current: key
            });
        },200);
    },200);
}


/**
 * Shuffles array in place. ES6 version
 * @param {Array} a items An array containing the items.
 */
export function shuffle(a) {
    for (let i = a.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [a[i], a[j]] = [a[j], a[i]];
    }
    return a;
}
